.overview {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.right-cbc {
    flex-grow: 1;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.right-cbc .ant-progress-circle .ant-progress-text {
    font-size: 30px;
}

.right-cbc.health-score .ant-progress-circle .ant-progress-text {
    font-size: 1.5em;
    font-weight: 500;
}

.circle-rcbc {
    width: 270px;
    height: 270px;
    background-color: white;
    border-radius: 50%;
    box-shadow: 4px 1px 18px 3px #d4d4d4;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 12px;
}

@media screen and (max-width: 1500px) {
    .left-cbc {
        width: 270px;
    }

    /* .mid-cbc {
        width: 160px;
        padding-left: 30px;
    } */
    /* .left-mbd {
        width: 200px;
    } */
    .circle-rcbc {
        width: 180px;
        height: 180px;
    }
}

.sessionsstuff .top-overview {
    height: 55%;
    margin: 1px !important;
}

.sessionsstuff .bottom-overview {
    margin: 1px !important;
    height: 35%;
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.sessionsstuff {
    justify-content: flex-end;
    padding-left: 120px;
}

.sessionsstuff .recharts-cartesian-axis-ticks text {
    font-size: 12px;
    font-weight: 600;
}

.sessionsstuff .box-bubbles {
    box-shadow: none !important;
    background-color: transparent !important;
}

.left-sectionstufftop {
    width: 60%;
    height: 250px;
    box-shadow: 2px 1px 8px 3px #d4d4d4;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.top-lsst {
    width: 90%;
    height: 100%;
}

.bottom-lsst {
    width: 80%;
    /* flex-grow: 1; */
    height: 230px;
    display: flex;
}

.left-blsst {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.left-blsst .title-topoverview {
    margin-bottom: 10px;
}

.sectionaladd {
    margin: 0px;
    margin-bottom: 20px;
    font-size: 35px;
    color: rgb(62, 233, 62);
    display: flex;
    align-items: center;
}

.sectionaladd .MuiSvgIcon-root {
    margin-right: 10px;
}

.right-blsst {
    flex-grow: 1;
    /* border: 1px solid orange; */
}

.sessionsstuff .box-bubbles {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.right-sectionstufftop {
    height: 100%;
    width: 35%;
    text-align: right;
}

.assessment-bottom{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    width:85%;
    margin:auto;
}

@media screen and (max-width: 1500px) {
    .right-sectionstufftop {
        width: 250px;
    }

    .sessionsstuff {
        padding-left: 0px;
    }

    .sessionsstuff .bottom-overview {
        height: 300px !important;
        width: 90%;
    }

    .sessionsstuff .top-overview {
        width: 95%;
    }
}

.sessionsstuff .bottom-overview {
    box-shadow: 2px 1px 8px 3px #d4d4d4;
}

.sessionsstuff .right-sectionstufftop .title-topoverview {
    font-size: 32px;
}

.right-sectionstufftop ul {
    margin: 0px;
    padding: 0px;
    overflow-y: auto;
    max-height: 180px;
    list-style-type: none;
}

.right-sectionstufftop ul li {
    margin: 30px 0px;
    font-weight: 600;
    letter-spacing: 1px;
    font-size: 17px;
}

/* .right-sectionstufftop ul::-webkit-scrollbar {
    width: 2px;
} */

.right-sectionstufftop ul::-webkit-scrollbar {
    width: 5px;
    height: 80%;
}

/* Track */

.right-sectionstufftop ul::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */

.right-sectionstufftop ul::-webkit-scrollbar-thumb {
    background: rgb(252, 248, 9);
}

/* Handle on hover */

.right-sectionstufftop ul::-webkit-scrollbar-thumb:hover {
    background: rgb(252, 248, 9);
}

/* .sessionsstuff .recharts-bar-rectangle path {
    fill: #F5F0FF;
} */

.sessionsstuff .bottom-overview .sectionaladd {
    color: rgb(255, 86, 114);
    font-size: 22px;
}

@media screen and (max-width: 1050px) {
    .sessionsstuff {
        padding-left: 0px;
        margin-top: 30px !important;
    }

    .left-sectionstufftop {
        width: 100% !important;
        height: auto !important;
    }

    .bottom-lsst {
        width: 100% !important;
        flex-direction: column !important;
        height: auto !important;
    }

    .left-blsst {
        text-align: center !important;
    }

    .right-blsst {
        width: 100% !important;
        padding-left: 0px !important;
        height: 500px !important;
        margin-top: 40px !important;
    }

    .sessionsstuff .right-blsst {
        height: 300px !important;
    }

    .right-sectionstufftop {
        width: 100% !important;
        text-align: center;
        margin-top: 40px;
    }

    .sessionsstuff .bottom-lsst {
        height: auto !important;
    }

    .sessionsstuff .bottom-overview {
        margin-top: 40px !important;
        height: auto !important;
    }

    /* .sessionsstuff .right-blsst .recharts-surface {
        width: 360px !important;
    } */
    .somepaddingbottomras {
        padding-bottom: 20px !important;
    }

    .sessionsstuff .left-blsst {
        height: auto !important;
    }
}
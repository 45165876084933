.happiness-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .happiness-modal-content {
    background: #fffcf8;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 430px;
  }
  
  .yeyTxt {
    font-size: 20px;
    font-weight: 600;
    font-family: "Quicksand";
    color: #394b42;
    text-align: center;
    margin-top: 20px;
  }

  @media (max-width: 480px) {
    .happiness-modal-content {
      width: 90%;
      margin: 0 20px;
    }
  }
.navbar-main {
	position: fixed;
    background-color: #FFFCF8;
    z-index: 99999999;
	width: 100%;
	height: 90px;
	border-bottom-left-radius: 25px;
	border-bottom-right-radius: 25px;
	box-shadow: 0px 3px 10px -3px #c2c2c2;
	padding: 0px 40px;
	align-items: center;
	justify-content: space-between;
	top: 0px;
	font-family: Brandon;
}

.container-main {
    width: 100%;
    max-width: 83.33%;
    margin: 0px auto;
}

.container-footer-section-two{
	width:100%;
	max-width: 83.33%;
	margin: 0px auto;
	display: flex;
	color: #ffffff;
}

.navbar-container {
    padding: 15px;
    display: flex;
    align-items: center;
}

.menu-navbar-container {
    flex-grow: 1;
	margin-top: 15px;
}

.menu-navbar-container ul {
    list-style-type: none;
    display: flex;
    justify-content: center;
}

.menu-navbar-container ul li {
    font-size: 18px;
    font-weight: 400;
    margin-right: 60px;
    cursor: pointer;
    position: relative;
}

.menu-navbar-container ul li a {
    color: black;
    text-decoration: none;
}

.navbar-main a {
    color: inherit;
    text-decoration: none;
}

.menu-navbar-container ul li.active:before {
    width: 100%;
}

.menu-navbar-container ul li.hoveranimate:hover:before {
    width: 100%;
}

.menu-navbar-container ul li::before {
    content: " ";
    width: 0px;
    position: absolute;
    height: 1px;
    background-color: black;
    bottom: -5px;
    transition: all .3s ease-in-out;
}

.left-navbar-container {
    flex-shrink: 0;
    display: flex;
    align-items: center;
}

.left-navbar-container .user-profile {
    color: black;
    text-decoration: none !important;
    margin-right: 6px;
    display: flex;
    align-items: center;
    gap:5px;
}

.left-navbar-container .user-profile p {
    font-size: 18px;
    font-weight: 500;
    margin: 0px;
}

.avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.left-navbar-container p {
    margin: 0px;
    font-style: normal;
    font-weight: 450;
    font-size: 24px;
    color: #6A7BCC;
    font-weight: 600;
    text-shadow: 0px 4px 24px rgba(106, 123, 204, 0.32);
}

.left-navbar-container button {
    background: linear-gradient(225.34deg, #FF7F7F 0%, #FF7FB7 98.93%);
    box-shadow: 0px 8px 32px -2px rgba(255, 127, 164, 0.24);
    border-radius: 360px;
    width: 116px;
    height: 40px;
    margin-left: 39px;
    border: none;
    outline: none;
    font-weight: 450;
    color: white;
    font-size: 16px;
}

.dropdown-corporates {
    width: 180px;
    left: -25px;
    position: absolute;
    background: rgba(183, 195, 255, 0.2);
    backdrop-filter: blur(40px);
    /* filter: blur(40px); */
    top: 125%;
    border-radius: 13.5559px;
    display: flex;
    flex-direction: column;
    padding: 15px;
    transition: all .3s ease-in-out;
    z-index: 99999;
}

.corporate-dropdownclosing {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(183, 195, 255, 0.2);
    z-index: 999;
    top: 0px;
}

.dropdown-corporates label {
    cursor: pointer;
    font-size: 16px;
    line-height: 23px;
    font-weight: 450;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #d3d3d3;
    transition: all .3s ease-in-out;
}

.dropdown-corporates label:hover, .dropdown-corporates label.active {
    color: gray;
}

.dropdown-corporates label:last-child {
    margin-bottom: 0px;
    padding-bottom: 0px;
    border-bottom: 0px;
}

.footer-main-new {
    background: #4F4F4F;
}

.footer-main-new .container-main {
    display: flex;
    color: white;
    padding-top: 30px;
}

.container-footer-inner {
    width: 20%;
}

.container-footer-inner:first-child {
    width: 25%;
}

.container-footer-inner:last-child {
    width: 35%;
}

.container-footer-section-two .container-footer-inner {
	width: 50% !important;
	margin-left: 5%;
	margin-right: 5%;
}

.main-fcfooter {
    font-weight: 400;
    font-size: 22px;
    margin-bottom: 20px;
    margin-top: 0px;
}

.firstcontainerfooter button {
    width: 145px;
    height: 40px;
    border-radius: 50px;
    background-color: white;
    color: black;
    border: none;
    outline: none;
    font-size: 17px;
    font-weight: 700px;
    letter-spacing: -0.231818px;
    font-family: sans-serif;
	margin-left: 15px;
}

.address-first-cfi {
    margin-top: 30px;
	text-align: center;
}

.address-first-cfi p {
    font-weight: 420;
    font-size: 17px;
}

.contact-first-cfi {
    width: calc(263px - 19px);
    height: 47px;
    border: 2px solid #FFFFFF;
    border-radius: 50px;
    margin-top: 40px;
    display: flex;
    align-items: center;
    padding-left: 19px;
}

.contact-first-cfi svg {
    font-size: 20px;
    margin-right: 16px;
}

.contact-first-cfi p {
    font-weight: 400;
    font-size: 17px;
    letter-spacing: -0.231818px;
    margin: 0px;
}

.contact-first-cfi p a {
    text-decoration: none;
    color: inherit;
}

.copyright-first-cfi p {
    margin-top: 10px;
    font-weight: 420;
    font-size: 18px;
}

.container-footer-inner ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}

.container-footer-inner ul li {
    font-weight: 390;
    font-size: 15px;
    line-height: 21px;
    margin-bottom: 15px;
}

.container-footer-inner ul li a {
    text-decoration: none;
    color: inherit;
}

.container-footer-inner ul li:first-child {
    color: #ea8a8a;
	text-decoration: underline;
	font-size:18px;
}

.container-footer-inner-copyright{
	width: 100%;
	text-align: center;
	border-top: 1px dashed #fff;
}

.heading-cfin {
    font-weight: 450;
    font-size: 24px;
    margin: 0px;
}

.buttons-cfin {
    margin-top: 37px;
    display: flex;
}

.app-store-download-bcfin {
    width: 180px;
    height: 50px;
    border-radius: 8px;
    background-color: white;
    margin-right: 34px;
    border: 3px solid #A6A6A6;
    display: flex;
    align-items: center;
    color: black;
    justify-content: center;
}

/* .app-store-download-bcfin .text-inner-bcfin p:first-child {
    letter-spacing: 0.4px;
    font-weight: 500;
}

.app-store-download-bcfin .text-inner-bcfin p:last-child {
    font-weight: 600;
    margin: 0px;
    font-size: 24px;
} */

.playstore-store-download-bcfin {
    width: 180px;
    height: 50px;
    background-color: black;
    border-radius: 8px;
    border: 3px solid #A6A6A6;
    align-items: center;
    display: flex;
    justify-content: center;
}

.buttons-cfin div svg {
    font-size: 38px;
    margin: 0px;
    margin-right: 10px;
}

.buttons-cfin div p {
    margin: 0px;
}

.text-cfin {
    margin-top: 0px;
    margin-bottom: 15px;
    font-weight: 390;
    font-size: 24px;
    line-height: 34px;
	margin-left: 25px;
}

.email-subscribe {
    width: 335px;
    height: 50px;
    display: flex;
    border-radius: 50px;
    overflow: hidden;
    border: 2px solid #FFFFFF;
    position: relative;
}

.email-subscribe input {
    padding: 10px;
    padding-left: 32px;
    background-color: transparent;
    font-weight: 390;
    font-size: 15px;
    line-height: 34px;
    color: #E0E0E0;
    outline: none;
    width: 100%;
    border: none;
    padding-right: 130px;
}

.email-subscribe input::placeholder {
    font-weight: 390;
    font-size: 18px;
    letter-spacing: 0.4px;
    color: #E0E0E0;
}

.email-subscribe button {
    width: 85px;
    background: #ea8a8a;
    border-radius: 50px;
    font-weight: 390;
    font-size: 18px;
    position: absolute;
    color: #fff;
    top: 5px;
    right: 5px;
    height: 80%;
	border: 0px solid #ea8a8a!important;
}

.icons-subscribe {
    margin-top: 15px;
    display: flex;
    padding-left: 40px;
    /* justify-content: flex-end; */
    width: 335px;
}

.icons-subscribe svg {
    margin-right: 32px;
    cursor: pointer;
    font-size: 20px;
}

.icons-subscribe svg:last-child {
    margin-right: 0px;
}

.menu-icon-navbar {
    display: none;
    font-size: 32px;
    z-index: 999;
}

.menu-open-modal {
    width: 60%;
    position: fixed;
    top: 30px;
    background: rgba(183, 195, 255, 0.2);
    backdrop-filter: blur(40px);
    /* filter: blur(40px); */
    display: flex;
    flex-direction: column;
    padding: 15px;
    transition: all .3s ease-in-out;
    padding-top: 86px;
    border-top-left-radius: 13px;
    border-bottom-left-radius: 13px;
    padding-bottom: 81px;
    right: -100%;
    z-index: 99;
}

.menu-open-modal label {
    cursor: pointer;
    font-size: 24px;
    line-height: 23px;
    font-weight: 450;
    margin-bottom: 40px;
    padding-bottom: 10px;
    font-weight: 400;
    position: relative;
}

.menu-open-modal .login {
    margin-bottom: 0px;
    padding-bottom: 0px;
    border-bottom: 0px;
    color: #6A7BCC;
}

.menu-open-modal .dropdown-corporates .login {
    color: initial;
}

/* corporate footer */

.corporate-footer {
    background: #81B8AE;
}

.corporate-footer .container-main {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 111px;
    padding-bottom: 136px;
}

.corporate-footer .take-totop-hpmain {
    position: absolute;
    bottom: 0px;
    right: 0px;
    flex-shrink: 0;
}

.inner-cco-main {
    padding-top: 24px;
    padding-bottom: 22px;
    width: 457px;
    background-color: white;
    border-radius: 24px;
    padding-left: 45px;
    margin-bottom: 48px;
}

.inner-cco-main h3 {
    margin: 0px;
    font-weight: 450;
    font-size: 32px;
    line-height: 120%;
    margin-bottom: 23px;
}

.inner-cco-main p {
    font-weight: 390;
    font-size: 18px;
    line-height: 120%;
    color: #4F4F4F;
    width: 100%;
    max-width: 349px;
    margin-bottom: 32px;
    margin-block-start: 1em;
    margin-block-end: 1em;
}

.inner-cco-main label {
    display: block;
}

.inner-cco-main label:nth-child(3) {
    margin-top: 13px;
    font-weight: 420;
    font-size: 18px;
    line-height: 120%;
    color: #4F4F4F;
}

.inner-cco-main label:nth-child(4) {
    font-weight: 390;
    font-size: 12px;
    line-height: 120%;
    color: #4F4F4F;
    margin-top: 7px;
}

.corporate-container-second {
    margin-left: 57px;
}

.corporate-container-second h2 {
    margin: 0px;
    color: white;
    font-weight: 450;
    font-size: 48px;
    line-height: 120%;
    width: 100%;
    max-width: 517px;
    text-align: left;
    margin: 0px auto;
    margin-bottom: 88px;
    margin-top: 28px;
}

.corporate-container-second .inner-cco-main:nth-child(2), .inner-cco-main:nth-child(1) {
    margin-bottom: 110px;
}

.fp-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #f8f8f8ad;
    z-index: 1000;
}

.fp-container .fp-loader {
    height: 350px;
    width: 350px;
    left: 50%;
    top: 43%;
    margin-top: -175px;
    margin-left: -175px;
    position: absolute;
    z-index: 1000;
}

.dropdown-profile {
    width: 180px;
    position: absolute;
    background: rgba(183, 195, 255, 0.2);
    backdrop-filter: blur(40px);
    /* filter: blur(40px); */
    top: 150%;
    right: 0px;
    border-radius: 13.5559px;
    display: flex;
    flex-direction: column;
    padding: 15px;
    transition: all .3s ease-in-out;
    z-index: 99999;
}







/* /////////test///////// */











.dropdown-profile label {
    cursor: pointer;
    font-size: 16px;
    line-height: 23px;
    font-weight: 450;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #d3d3d3;
    transition: all .3s ease-in-out;
    display: flex;
    align-items: center;
}

.label-icon {
    margin-right: 10px;
}

.dropdown-profile a:hover, .dropdown-profile a.active {
    color: gray;
}
/* corporate footer */

@media only screen and (max-width: 860px) {
	.navbar-main {
		padding: 0px;
	}
	
	.menu-open-modal {
		top: 5px;
	}
	
    .menu-navbar-container ul {
        display: none;
    }

    .left-navbar-container p {
        display: none;
    }

    .left-navbar-container button {
        margin-left: 0px;
    }

    .menu-icon-navbar {
        display: inline-block;
        margin-left: 11.4px;
    }

    .container-main {
        max-width: 95%;
    }
	
	.container-footer-section-two {
        max-width: 95%;
    }
	
	.address-first-cfi{
		margin-top: 20px;
		text-align:center;
	}

    .dropdown-corporates {
        position: static;
        background-color: transparent;
        width: 100%;
        margin-top: 15px;
    }

    .left-navbar-container font{
        display: none;
    }


    /* footer */

    .footer-main-new .container-main {
        padding-top: 20px !important;
    }

    .main-fcfooter {
        display: none;
    }

    .firstcontainerfooter button {
        display: none;
    }

    .address-first-cfi p {
        font-size: 12px;
    }

    .container-footer-inner {
        width: 28% !important;
    }
	
	.container-footer-inner-copyright{
		width: 100%;
		text-align: center;
		border-top: 1px dashed #fff;
	}
	
	.container-footer-inner:last-child{
		width: 44%!important;
	}
	
	.container-footer-section-two .container-footer-inner {
        width: 50% !important;
		margin-left: 5%;
		margin-right: 5%;
    }
	
	.text-cfin {
		margin-left: 0px;
	}

    .round-ttotop-hp {
        width: 23px !important;
        height: 23px !important;
        font-size: 18px !important;
    }

    .take-totop-hpmain {
        margin-top: 30px !important;
    }
	
	.container-footer-inner ul li {
		font-size: 12px!important;
		line-height: 21px;
	}

    .container-footer-inner ul li:first-child {
        font-size: 14px!important;
		text-decoration: underline;
    }

    .container-footer-inner li {
        font-size: 10px !important;
    }

    .container-footer-inner:nth-child(2) ul:last-child {
        margin-top: 0px;
    }

    .copyright-first-cfi p {
        color: #fff;
        font-size: 12px !important;
		margin-top: 10px;
    }

    .icons-subscribe {
        width: 90%;
		max-width: 200px;
		margin-left:10%;
        padding-left: 0px;
    }

    .icons-subscribe svg {
        margin-right: 8px !important;
        font-size: 18px !important;
    }

    .text-cfin {
        font-size: 12px !important;
        margin-bottom: 5px !important;
    }

    .email-subscribe {
        width: 85%;
		max-width: 200px;
        height: 30px;
    }
	
	.container-footer-inner label{
		font-size: 12px;
	}

    .email-subscribe input {
        padding-left: 11px;
        padding-right: 50px;
        font-size: 12px;
    }

    .email-subscribe button {
        width: 42px;
        font-size: 9px;
        padding: 0px;
        top: 2px;
        right: 2px;
        height: 80%;
    }

    .email-subscribe input::placeholder {
        font-weight: 390;
        font-size: 8.6px;
        letter-spacing: 0px;
        color: #E0E0E0;
    }

    .contact-first-cfi {
        width: 130px;
        height: 25px;
        padding-left: 5px;
    }

    .contact-first-cfi p {
        font-size: 12px;
    }

    .contact-first-cfi svg {
        font-size: 14px;
        margin-right: 2px;
    }

    .corporate-footer {
        padding-top: 56px;
    }

    .corporate-footer h2 {
        font-size: 24px;
        color: white;
        max-width: 207px;
        text-align: center;
        margin: 0px auto;
    }

    .corporate-container-second {
        display: flex;
        align-items: stretch !important;
        overflow-x: auto;
        margin-left: 0px;
        padding-left: 30px;
    }

    .corporate-container-second::-webkit-scrollbar {
        display: none;
    }

    .inner-cco-main {
        /* padding-top: 97px; */
        flex-shrink: 0;
        width: 256px;
        /* padding-left: 15px; */
        margin-right: 23px;
        margin-bottom: 0px !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 20px;
        justify-content: center;
    }

    .inner-cco-main h3 {
        font-size: 17.9256px;
    }

    .inner-cco-main p {
        max-width: 160px;
        font-size: 10.0832px;
    }

    .inner-cco-main label:nth-child(4) {
        font-size: 10.08px;
        line-height: 12.1px;
    }

    .inner-cco-main label:nth-child(5) {
        font-size: 6.72px;
        line-height: 8.07px;
    }

    .corporate-footer .container-main {
        padding-bottom: 66px;
        padding-top: 70px;
    }
}
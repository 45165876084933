.forgot-screen-main-container {
  width: 100%;
  margin: 0px auto;
  min-height: 100vh;
  display: flex;
  background-color: #e8fdf2;
}

.forgot-box {
  width: 440px;
  height: 420px;
  max-width: 100%;
  max-height: 90%;
  background-color: #fffcf8;
  border-radius: 25px;
  box-shadow: 3px 3px 18px 3px #ececec;
  margin-top: 150px;
  margin-left: 100px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.forgot-box-heading {
  font-size: 25px;
  text-align: center;
  font-weight: 600;
  font-family: "Quicksand";
  color: #394b42;
  margin-top: 20px;
}

.forgot-form-inner-stuff-lcm {
  margin-top: 20px;
}

.forgot-form-inner-stuff-lcm input {
  width: 365px !important;
  max-width: 360px;
  border-radius: 50px;
  background-color: #fffcf8;
  box-shadow: 1px 1px 10px 1px #ececec;
  border: none;
  padding: 12px;
  padding-left: 18px;
  outline: none;
  box-sizing: border-box;
}

.alert-field-fisl {
  color: red;
  font-size: 12.5px;
  margin: 0px;
  letter-spacing: 1px;
  font-weight: 600;
  font-family: "Quicksand";
  margin-left: 10px;
  margin-top: 5px;
}

.btn-forgotmain {
  width: 360px;
  margin-top: 20px;
}

.btn-forgotmain button {
  width: 100%;
  height: 45px;
  border-radius: 50px;
  background-color: #49af7c;
  border: none;
  outline: none;
  color: white;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.5px;
  font-family: "Quicksand";
}

.noaccountpara {
  width: 360px;
  color: #394b42;
  font-weight: 600;
  margin-top: 15px;
  border-radius: 50px;
  background-color: #e8ebff;
  border: none;
  outline: none;
  font-size: 15px;
  letter-spacing: 1px;
  font-family: "Quicksand";
  text-align: center;
  padding: 11.5px;
}

.noaccountpara a {
  color: #394b42;
}

.forgot-box-Image {
  width: 450px;
  height: 450px;
  max-width: 100%;
  max-height: 90%;
  margin-top: 140px;
  margin-left: 150px;
  z-index: 99;
  text-align: center;
  padding: 20px;
  background-color: #e8fdf2;
}

.forgot-box-Image h3 {
  font-size: 30px;
  font-weight: 600;
  font-family: "Quicksand";
  color: #394b42;
  margin-bottom: 10px;
  margin-top: 20px;
}

.forgot-box-Image p {
  font-size: 20px;
  font-weight: 400;
  font-family: "Quicksand";
  color: #394b42;
  margin-bottom: 70px;
}

.forgot-box-Image img {
  width: 100%;
  height: 230px;
  object-fit: contain;
}

.forgot-txt {
  font-size: 13px;
  text-align: center;
  font-weight: 600;
  font-family: "Quicksand";
  color: #394b42;
  margin-top: 50px;
}

.forgot-txt a {
  color: #49af7c;
}

.mobileScreen-forgot-box-Image {
  z-index: 99;
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  font-family: "Quicksand";
  color: #394b42;
  margin-top: 70px;
  justify-content: center;
  background-color: transparent;
  transition: background-color 1s ease-in-out;
}

.mobileScreen-forgot-box-Image h3 {
  font-size: 25px;
  font-weight: 600;
  font-family: "Quicksand";
  color: #394b42;
  margin-top: 30px;
}

.mobileScreen-forgot-box {
  background-color: transparent;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
}

.mobileScreen-forgot-form-inner-stuff-lcm {
  margin-top: 20px;
}

.mobileScreen-forgot-form-inner-stuff-lcm input {
  width: 100%;
  border-radius: 50px;
  background-color: #fffcf8;
  box-shadow: 1px 1px 10px 1px #ececec;
  border: 0px;
  padding: 12px;
  padding-left: 18px;
  outline: none;
}

.container {
    font-family: "Quicksand";
}
.content {
    max-width: 570px;
    margin: 0 auto;
}
.contentTop {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
}
.contentTopLeft {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.contentTopLeftDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 34px;
    font-weight: 600;
    gap: 15px;
    color: #49AF7C;
} 
.contentTopLeftDiv img {
    max-width: 60px;
}
.contentTopLeftText {
    font-size: 24px;
    font-weight: 600;
}
.contentTopImage {
    max-width: 170px;
    object-fit: contain;
}

.contentMain {
    margin-top: 15px;
}
.contentMainHeading {
    font-size: 18px;
    font-weight: 600;
    padding-left: 25px;
}
.contentMainContainer {
    padding: 0 25px;
    border-radius: 20px;
    box-shadow: 0px 0px 10px 0px #394B421A;
}
.contentMainContainerItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 10px 0;
    border-bottom: 1px solid #aaaa;
}
.contentMainContainerItem:last-child {
    border-bottom: 0px;
}
.contentMainContainerItemLeft {
    display: flex;
    align-items: center;
    gap: 10px;
}
.contentMainContainerItem img {
    width: 35px;
    height: 35px;
    object-fit: contain;
}
.contentMainContainerItemLeft div {
    display: flex;
    flex-direction: column;
}
.contentMainContainerItemLeft div:first-child {
    font-size: 18px;
    font-weight: 500;

}
.contentMainContainerItemLeft div {
    font-size: 16px;
    
}
.contentMainContainerItem button {
    font-size: 16px; 
    width: 100px;
    font-weight: 500;
    color: #fff;
    padding: 5px 10px;
    border-radius: 100px;
    border-width: 0;
    background-color: #49AF7C;
}

.contentBottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    padding: 10px 25px;
    margin-top: 15px;
    background-color: #CBD1F0;
}
.contentBottomLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;
    max-width: 40%;
}
.contentBottomLeft div:first-child {
    font-size: 18px;
    font-weight: 500;
}
.contentBottom button {
    font-size: 16px; 
    width: 100px;
    font-weight: 500;
    color: #fff;
    padding: 5px 10px;
    border-radius: 100px;
    border-width: 0;
    background-color: #49AF7C;
}

@media screen and (max-width: 1050px) {
    .container {
        padding: 70px 20px;
    }
    .contentTopLeftText {
        font-size: 18px;
        text-align: center;
    }
}
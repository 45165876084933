.dashboardlistcontainer {
  width: 100%;
  height: 100%;
  display: flex;
}

.left-dlc {
  width: 45%;
  height: 100%;
}

.right-dlc {
  width: 55%;
  height: 100%;
}

.container-rdlc {
  width: 95%;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.right-cmudo {
  margin: auto;
}

.right-cmudo ul {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  width: 100%;
}

.right-cmudo ul li {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
}

.right-cmudo ul li img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.right-cmudo ul li:nth-child(2n + 1) {
  background-color: #f8f8f8;
}

.username {
  font-size: 14px;
  color: #7fcbee;
  margin: 0px;
  font-weight: 500;
}

.smalldesc {
  margin: 0px;
  font-size: 13px;
}

.calendar-dlc {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.6px;
  display: flex;
  align-items: center;
  margin: 0px;
}

.calendar-dlc .MuiSvgIcon-root {
  margin-right: 5px;
}

.btn-greendlc {
  background-color: green;
  padding: 5px 12px;
  border: none;
  outline: none;
  color: white;
  border-radius: 20px;
  font-size: 9px;
  letter-spacing: 1px;
  width: 80px;
}

.btn-cashback {
  background-color: transparent;
  padding: 5px 0px;
  border: none;
  outline: none;
  color: #ff8a48;
  border-radius: 20px;
  border: 1px solid #ff8a48;
  font-size: 9px;
  letter-spacing: 1px;
  width: 80px;
}

.btn-reddlc {
  padding: 8px 18px;
  border: 2px solid red;
  color: red;
  outline: none;
  border-radius: 20px;
  font-size: 13px;
  letter-spacing: 1px;
  background-color: transparent;
}

.btn-bluedlc {
  padding: 8px 18px;
  border: 2px solid #7fcbee;
  color: #7fcbee;
  outline: none;
  border-radius: 20px;
  font-size: 13px;
  letter-spacing: 1px;
  background-color: transparent;
}

.price-dlc {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}

.price-dlc .MuiSvgIcon-root {
  margin-right: 5px;
}

.dashboard-containerlctop {
  width: 100%;
  height: 220px;
  border-radius: 20px;
  /* background-image: url("https://ucarecdn.com/c807a397-a4ec-462b-b9fb-7d61067e0394/perfectbgfordiv.svg"); */
  background: linear-gradient(131.58deg, rgba(180, 189, 233, 0.53) 1.34%, #7E8ED9 85.37%);
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  font-family: Brandon;
  letter-spacing: 0.5px;
}

.firstheading-clct {
  color: white;
  font-size: 24px;
  margin: 0px;
  margin-bottom: 0px;
  text-align: center;
}

.secheading-clct {
  color: white;
  margin: 0px;
  font-weight: 500;
  font-size: 28px;
  margin-bottom: 15px;
  text-align: center;
}

.bottomheading-clct {
  color: white;
  font-size: 18px;
  margin: 0px;
  text-align: right;
  position: absolute;
  bottom: 10px;
  right: 20px;
}

.top-rdlc {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  font-family: Brandon;
  font-weight: 500;
}

.graybuttons {
  display: flex;
  align-items: center;
}

.top-rdlc .title-topoverview {
  margin: 0px;
  font-size: 1.4em;
  line-height: 2;
  font-weight: 500;
}

.graybuttons button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid gray;
  margin-left: 8px;
  color: gray;
  padding: 3px;
  position: relative;
  outline: none;
}

.graybuttons select {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid gray;
  margin-left: 8px;
  color: gray;
  padding: 3px;
  position: relative;
  outline: none;
}

.graybuttons button:first-child {
  padding: 3px 10px;
}

.graybuttons button input {
  border: none;
  box-sizing: border-box;
  outline: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  opacity: 0;
}

.graybuttons button input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.bottom-containerlctop {
  width: 100%;
  margin-top: 20px;
  height: 370px;
}

.headings-containerlctop {
  width: 100%;
  margin-top: 50px;
}

.headings-containerlctop .top-rdlc {
  margin-top: 5px;
  margin-bottom: 5px;
}

@media screen and (max-width: 1500px) {
  .top-mudo {
    margin-left: 0px;
  }
  .username {
    font-size: 14px;
  }
  .smalldesc {
    font-size: 11px;
  }
  .btn-greendlc {
    font-size: 9px;
    padding: 5px 12px;
    width: 80px;
  }
  .calendar-dlc {
    font-size: 11px;
  }
  .price-dlc {
    font-size: 13px;
  }
  .price-dlc .MuiSvgIcon-root,
  .calendar-dlc .MuiSvgIcon-root {
    font-size: 15px;
  }
  .bottom-containerlctop {
    height: 250px !important;
  }
}

@media screen and (max-width: 1050px) {
  .dashboardlistcontainer {
    flex-direction: column !important;
  }
  .left-dlc {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .right-dlc {
    width: 100%;
  }
  .bottom-containerlctop {
    width: 100%;
    height: 400px;
  }
  .container-rdlc {
    width: 100%;
  }
  .username {
    font-size: 14px;
  }
  .smalldesc {
    display: none;
  }
  .price-dlc {
    font-size: 10px;
  }
  .price-dlc .MuiSvgIcon-root,
  .calendar-dlc .MuiSvgIcon-root {
    font-size: 14px;
  }
  .top-rdlc .title-topoverview {
    width: 100%;
    font-size: 14px !important;
    margin: 0px !important;
  }
  .container-rdlc button {
    font-size: 7px;
    padding: 8px 12px;
  }
  .calendar-dlc {
    font-size: 7px;
  }
  .right-dlc {
    margin-bottom: 30px;
  }
  .bottomheading-clct {
    bottom: 10px;
  }
  .dashboardofboxes .top-rdlc .title-topoverview {
    margin-top: 0px !important;
  }
  .headings-containerlctop .top-rdlc {
    margin-top: 20px;
  }
  .left-cmudo .top-rdlc .title-topoverview {
    width: 100%;
    margin: 0px !important;
  }
  .left-cmudo {
    width: 100% !important;
    margin-top: 30px !important;
  }
  .top-mudo {
    margin-left: 0px !important;
  }
  .top-mudo .title-topoverview {
    margin: 0px !important;
  }
  /* .topbottomparas {
        max-width: 50px;
    } */
  .right-cmudo {
    padding-left: 0px !important;
  }
}

@media screen and (max-width: 767px) {
  .right-cmudo ul li img {
    display: none;
  }
  .container-rdlc button {
    width: 60px;
  }
  .graybuttons {
    margin-top: 10px;
    justify-content: right;
    width: 100%;
  }
}

/* Updated stuff */

.mainuserdashboardoverview {
  width: 100%;
}

.top-mudo {
  margin-left: 40px;
}

.container-mudo {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 40px;
}

.left-cmudo {
  width: 450px;
}

.right-cmudo {
  width: 750px;
  /* background-color: purple; */
  padding-left: 50px;
}

@media screen and (max-width: 1580px) {
  .right-cmudo {
    width: 650px;
  }
}

@media screen and (max-width: 1500px) {
  .left-cmudo {
    width: 350px;
  }
  .right-cmudo {
    width: 490px;
  }
}

@media screen and (max-width: 767px) {
  .secheading-clct {
    font-size: 18px !important;
  }
  .firstheading-clct {
    font-size: 15px !important;
  }
  .container-mudo {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0 0px;
  }
}
.dashboardofboxes {
    font-family: "Quicksand", sans-serif;
    color: #394B42;
}

.dashboardbooking {
    width: 100%;
}

.top-containerbooking {
    margin-bottom: 25px;
}

.bottom-containerbooking {
}
.bottom-containerbooking-header {
    display: flex;
    align-items: center;
    max-width: 80%;
    margin-bottom: 15px;
    gap: 20px;
}
.bottom-containerbooking-header button {
    outline: none;
    border: 0px;
    background-color: transparent;
}
.bottom-containerbooking-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.bottom-containerbooking-header-txt {
    font-size: 1.5em;
    font-weight: 600;
}
.top-containerbooking .dashboard-containerlctop {
    width: 90%;
    height: 250px;
    position: relative;
}

.dashboard-heading{
    font-size: 1.8em;
    font-weight: 600;
    margin-bottom: 15px;
}
.dashboard-heading img {
    max-width: 30px;
    object-fit: contain;

}

.booknow-card{
    height: 260px;
    border-radius: 20px;
    background-color: #E0F3E0;
    overflow: hidden;
    position: relative;
    padding: 20px 25px;
    display: flex;
    flex-direction: column;
    color: inherit;
    text-decoration: none;
    max-width: min(70%, 600px);
    margin: 0 auto;
}

.booknow-card:hover{
    text-decoration: none;
    color: inherit;
}

.booknow-card .firstheading{
    font-size: 1.75em;
    font-weight: 600;
    margin-bottom: 12px;
}
.booknow-card .firstheading span {
    color: #87B19C;
    mix-blend-mode: multiply;
}
.booknow-card .bottomheading{
    font-size: 1.2em;
    font-weight: 600;
    color: rgba(107, 113, 140, 1);
    width:58%;
}
.book-img{
    max-height:75%;
    position: absolute;
    top:50%;
    right:5%;
    object-fit: contain;
    transform: translateY(-50%);
}
.book-btn-cont {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.book-btn{
    border: 0px;
    padding: 14px 20px;
    font-weight: 600;
    color: #fff;
    background-image: url("assets/svg_icons/button-bg.svg");
    background-color: transparent;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 250px;
    /* margin-top: 30px; */
}
.book-btn p{
    margin:0;
    color:white;
    font-size: 1.25em;
    font-weight: 600;
}
.playicontmcbc {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0px 14px 34px 0px #2225A966;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}

.playicontmcbc .MuiSvgIcon-root {
    color: #3D41D7;
    font-size: 20px;
}

.btn-changemood {
    position: absolute;
    z-index: 999;
    width: 80px;
    height: 80px;
    background-color: white;
    bottom: -30px;
    box-shadow: 2px 1px 8px 3px #d4d4d4;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    right: -36px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-changemood label {
    background-color: #3D41D7;
    width: 50px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    box-shadow: 2px 1px 15px 3px #7b7ecc;
    letter-spacing: 0.5px;
    color: white;
    border-radius: 10px;
}

.left-cbc {
    width: 300px;
    background-color: rgba(232, 235, 255, 1);
    height: 331px;
    border-radius: 13px;
    position: relative;
    overflow: hidden;
}

.container-lcbc {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    position: relative;
    width: 100%;
    height: 100%;
    position: absolute;
    right: -300px;
    -webkit-animation: slide 0.5s forwards;
    -webkit-animation-delay: 0.5s;
    animation: slide 0.5s forwards;
    animation-delay: 0.5s;
}

.blogimage-cbc {
    width: 100%;
    height: 150px;
    background: rgba(197, 207, 255, 1);
    border-radius: 13px;
    margin-bottom: 10px;
}

.blogimage-cbc img {
    width: 100%;
    height: 100%;
    object-fit:contain;
    border-radius: 13px;
}

.left-cbc img {
    width: 96%;
    margin-bottom: 10px;
}

.absolutebottomlcbc {
    position: absolute;
    bottom: 0px;
    left: 20px;
    color: gray;
}

.latestupdates-cbc {
    width: 90%;
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 20px;
    /* letter-spacing: 1px; */
    display: flex;
    align-items: center;
    flex-grow: 1;
    font-family: Brandon;
    letter-spacing: 0.5px;
}

.latestupdates-cbc button {
    background-image: linear-gradient(to right, #3D40D7, #5455af);
    box-shadow: 3px 3px 18px 3px #7a7ce7;
    border: none;
    outline: none;
    padding: 5px 10px;
}

.latestupdates-cbc button .MuiSvgIcon-root {
    color: white;
}

.mid-cbc {
    width: 260px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.mid-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    margin: 0px 20px;
}

.top-mcbc {
    background-color: rgba(220, 246, 233, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 14px;
    margin-bottom: 30px;
    height: 140px;
}

.mid-container .top-mcbc{
   width:230px;
}

/* .top-mcbc .MuiSvgIcon-root {
    margin-bottom: 15px;
    font-size: 35px;
} */

.top-mcbc p {
    margin: 0px;
    font-weight: 500;
    font-size: 1.4em;
    font-family: Brandon;
}
.top-mcbc .playicon-mcbc{
    color: rgba(55, 158, 144, 1); 
    margin-bottom: 10px;
}

.bottom-mcbc {
    background-color: rgba(55, 158, 144, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 14px;
    color: white;
    height: 140px;
}

.mid-container .bottom-mcbc{
    width:230px;
}

.bottom-mcbc .MuiSvgIcon-root {
    margin-bottom: 15px;
    font-size: 35px;
}

.bottom-mcbc p {
    font-size: 1.4em;
    font-weight: 500;
    margin: 0;
    text-align: center;
    font-family: Brandon;
}

.right-cbc {
    flex-grow: 1;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.progress-container{
    flex-grow: 1;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    align-self: center;
    padding: 20px;
    font-family: Brandon;
}

.progress-container p{
    color: rgba(107, 113, 140, 1);
    margin-top:10px;
    font-size: 1.5em;
}

.circle-rcbc {
    width: 270px;
    height: 270px;
    border-radius: 135px;
    box-shadow: 4px 1px 18px 3px #d4d4d4;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
	font-size:12px;
}

.progress-container .circle-rcbc {
    width: 250px;
    height: 250px;
    border-radius: 125px;
    background-color: rgba(252, 242, 216, 1);
    box-shadow: none;
}

.circle-rcbc p:first-child {
    font-size: 45px;
    margin: 0px;
    font-weight: 600;
    text-align: center;
}

.progress-container .circle-rcbc p:first-child {
    font-size: 45px;
    margin: 0px;
    font-weight: 600;
    text-align: center;
}

.right-cbc .ant-progress-circle .ant-progress-text {
    font-size: 45px;
    font-weight: 600;
    top: 43%;
}

.right-cbc .ant-progress-circle-trail {
    stroke: transparent;
}

.progress-container .ant-progress-circle .ant-progress-text {
    font-size: 50px;
    font-weight: 500;
    top: 43%;
}

.progress-container .ant-progress-circle-trail {
    stroke-width: 2px;
    stroke: rgb(255, 255, 255);
}

.circle-rcbc p:last-child {
    margin: 0px;
    text-align: center;
    margin-top: 5px;
    position: absolute;
    top: 53%;
    width: 140px;
    animation: zoom-in-zoom-out 1s ease forwards;
}

.planner-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.planner-section p {
 font-size: 1.2em;
 font-weight: 500;
 margin: 0;
}
.planner-section.skeleton p {
    max-width: 150px;
}
.planner-section-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
}
.planner-section-card {
    min-height: 150px;
    border-radius: 20px;
    display: flex; 
    padding: 10px 20px;
    border: 0px;
    outline: none;
    text-align: left;
}
.skeleton .planner-section-card {
    flex: 1;
}
.planner-section-card img {
    max-height: 110px;
    align-self: center;
    /* z-index: -1; */
}
.planner-section-card-content {
    flex: 1;
    display: flex;
    gap: 10px;
    flex-direction: column;
    max-width: 200px;
    margin-right: 10px;
}
.planner-section-card-content h3 {
    font-size: 1.2em;
    font-weight: 500;
    margin-bottom: 5px;
}
.planner-section-card-content p {
    font-size: 12px;
}
.planner-section-card-check {
    align-self: center;
    background-image: url("assets/svg_icons/double_circle_white.svg");
    background-color: transparent;
    background-position: center;
    background-size: contain;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.planner-section-card-content-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
}
.planner-section-card-content-bottom img {
    max-width: 30px;
    background-color: transparent;
}

.circle-absolute-rcbc {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 220px;
    height: 220px;
    border: 30px solid #FF8832;
    border-radius: 50%;
}

.moodcontent-heading{
    font-size: 1.8em;
    font-weight: 500;
    font-family: Brandon;
}

.right-cbc .ant-progress {
    width: 100% !important;
    height: 100% !important;
}

.progress-container .ant-progress {
    width: 109% !important;
    height: 109% !important;
}

.right-cbc .ant-progress-circle .ant-progress-inner {
    width: 100% !important;
    height: 100% !important;
    font-size: 24px !important;
    margin-top: 0px !important;
    background-color: rgba(252, 242, 216, 1);
    border-radius:50%;
}

.progress-container .ant-progress-circle .ant-progress-inner {
    width: 100% !important;
    height: 100% !important;
    font-size: 24px !important;
    margin-top: 0px !important;
    border-radius:50%;
}

@media screen and (max-width: 499px){
    .top-containerbooking {
        margin-bottom: 25px;
    }
    .top-containerbooking .dashboard-containerlctop {
        width: 90%;
        height: 250px;
        position: relative;
    }
    .book-img {

    }

    .booknow-card {
        max-width: 100vh
    }
}
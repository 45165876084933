.usersstuff .top-overview {
    height: 50%;
}

.usersstuff .bottom-overview {
    weight: 100%;
    height: 50%;
    padding-top: 10px;
    align-items: center;
    justify-content: space-between;
}

.usersstuff .top-overview .box-bubbles {
    border: 0px;
    box-shadow: 2px 1px 8px 3px #d4d4d4;
    width: 95%;
    flex-wrap: wrap;
    max-height: none !important;
}

.left-usersstuff {
    width: 50%;
    height: 100%;
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.filters-user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.right-usersstuff {
    /* height: 95%; */
    width: 50%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.info-pie {
    position: absolute;
    bottom: 70px;
    right: 150px;
}

.inner-infopie {
    display: flex;
    align-items: center;
    margin: 15px 0px;
}

.inner-infopie .colorbox {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    border-radius: 50%;
}

.inner-infopie p {
    margin: 0px;
}

.pie-chart-usersstuff {
    width: 50%;
    height: 250px;
}

.container-lus {
    padding: 60px 50px;
    background-color: white;
    text-align: center;
    box-shadow: 2px 1px 8px 3px #d4d4d4;
    border-radius: 12px;
}

.title-clus {
    color: #379E90;
    font-size: 22px;
    font-weight: 700;
    margin: 0px;
    margin-bottom: 5px;
}

.count-clus {
    color: #379E90;
    margin: 0px;
    font-size: 86px;
    font-weight: 700;
}

.desc-clus {
    display: flex;
    align-items: center;
    font-weight: 600;
    margin-bottom: 0px;
}

.usersstuff .box-bubbles {
    padding: 20px;
    display: flex;
    align-items: flex-end;
}

.mid-bb-users {
    /* flex-grow: 1; */
    height: 215px;
    width: 400px;
    margin: 0px 40px;
}

.left-bb-users {
    text-align: right;
}

.left-bb-users .title-topoverview {
    justify-content: flex-end;
}

.right-bb-users {
    width: 200px;
    margin-left: auto;
}

.user-area-chart {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1500px) {

    .left-bb-users .title-topoverview,
    .right-bb-users .title-topoverview {
        font-size: 18px;
    }

    .left-bb-users,
    .right-bb-users {
        width: 150px;
    }

    .mid-bb-users {
        width: 300px;
    }

    .left-usersstuff {
        width: 300px;
    }

    .container-lus {
        padding: 30px;
    }

    .info-pie {
        right: 80px;
    }
}

@media screen and (max-width: 1050px) {
    .top-overview {
        width: 100% !important;
    }

    #new-sign-up {
        margin-right: 0px !important;
        height: 200px !important;
        margin-bottom: 30px;
    }

    .user-area-chart {
    width: 100%;
        height: 80% !important;
    }

    .right-app-stuff {
        height: auto !important;
        padding-left: 0px !important;
        padding-bottom: 0px !important;
        padding-top: 0px !important;
    }

    .usersstuff .top-overview .title-topoverview {
        width: 100% !important;
    }

    .usersstuff .top-overview .box-bubbles {
        width: 100% !important;
        padding: 0px !important;
        flex-direction: column !important;
        margin-top: 15px !important;
        max-height: none !important;
        height: auto !important;
    }

    .left-bb-users {
        width: 100% !important;
        text-align: center !important;
    }

    .left-bb-users .title-topoverview,
    .sectionaladd {
        justify-content: center !important;
    }

    .right-bb-users {
        width: 100%;
        text-align: center !important;
    }

    .mid-bb-users {
        height: 350px !important;
        width: 100% !important;
        margin: 0px !important;
    }

    .usersstuff .mid-bb-users .recharts-responsive-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 5px;
    }

    .usersstuff .bottom-overview {
        flex-direction: column !important;
        width: 100% !important;
        height: auto !important;
        padding-top: 40px !important;
    }

    .pie-chart-usersstuff {
        height: 190px !important;
        width: 70% !important;
    }

    .right-usersstuff {
        width: 100% !important;
    }

    .left-usersstuff {
        width: 100% !important;
    }

    .container-lus {
        width: 100% !important;
    }

    .info-pie {
        right: 20px;
        bottom: 40px;
    }

    .usersstuff .top-overview .title-topoverview {
        margin-top: 5px !important;
    }
}
.email-screen-main-container {
  width: 100%;
  margin: 0px auto;
  min-height: 100vh;
  display: flex;
  background-color: #e8ebff;
}

.email-box {
  width: 440px;
  height: 480px;
  max-width: 100%;
  max-height: 90%;
  overflow: hidden;
  background-color: #fffcf8;
  border-radius: 25px;
  box-shadow: 3px 3px 18px 3px #ececec;
  margin-top: 150px;
  margin-bottom: 50px;
  margin-left: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.email-box-heading {
  font-size: 17px;
  text-align: center;
  font-weight: 600;
  font-family: "Quicksand";
  color: #394b42;
}

.email-form-inner-stuff-lcm {
  margin-top: 20px;
}

.email-form-inner-stuff-lcm input {
  width: 365px !important;
  max-width: 360px;
  border-radius: 50px;
  background-color: #fffcf8;
  box-shadow: 1px 1px 10px 1px #ececec;
  border: none;
  padding: 12px;
  padding-left: 18px;

  outline: none;
  box-sizing: border-box;
}

.alert-field-fisl {
  color: red;
  font-size: 12.5px;
  margin: 0px;
  letter-spacing: 1px;
  font-weight: 600;
  font-family: "Quicksand";
  margin-left: 10px;
  margin-top: 5px;
}

.email-btn-main {
  width: 360px;
  margin-top: 20px;
}

.email-btn-main button {
  width: 100%;
  height: 45px;
  border-radius: 50px;
  background-color: #49af7c;
  border: none;
  outline: none;
  color: white;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.5px;
  font-family: "Quicksand";
}

.or-main-lw {
  width: 360px;
  display: flex;
  margin-top: 20px;
  align-items: center;
}

.small-line-or {
  flex-grow: 1;
  height: 0.8px;
  background-color: #394b42;
}

.noaccountpara {
  width: 360px;
  color: #394b42;
  font-weight: 600;
  margin-top: 15px;
  border-radius: 50px;
  background-color: #e8ebff;
  border: none;
  outline: none;
  font-size: 15px;
  letter-spacing: 1px;
  font-family: "Quicksand";
  text-align: center;
  padding: 11.5px;
}

.email-txt {
  font-size: 13px;
  text-align: center;
  font-weight: 600;
  font-family: "Quicksand";
  color: #394b42;
  margin-top: 10px;
}

.email-txt a {
  color: #49af7c;
}

.email-box-Image {
  width: 450px;
  height: 450px;
  max-width: 100%;
  max-height: 90%;
  margin-top: 160px;
  margin-left: 150px;
  z-index: 99;
  text-align: center;
  padding: 20px;
  background-color: #e8ebff;
}

.email-box-Image h3 {
  font-size: 30px;
  font-weight: 600;
  font-family: "Quicksand";
  color: #394b42;
  margin-bottom: 10px;
}

.email-box-Image img {
  width: 100%;
  height: 230px;
  object-fit: contain;
}

.forgot-Password-txt {
  font-size: 12px;
  font-weight: 400;
  font-family: "Quicksand";
  color: #49af7c;
  align-self: flex-end;
  margin-top: 10px;
  padding-right: 50px;
}

/* For tablets and medium screens (max-width: 1024px) */
@media screen and (max-width: 1024px) {
  .email-screen-main-container {
    flex-direction: column;
    height: auto;
    padding: 20px;
  }

  .email-box {
    margin-left: 0;
    margin-top: 50px;
    margin-bottom: 20px;
  }

  .email-box-Image {
    width: 80%;
    margin-left: 0;
    margin-top: 20px;
  }

  .email-box-Image img {
    width: 80%;
    height: auto;
  }
}

/* For mobile screens (max-width: 768px) */
@media screen and (max-width: 768px) {
  .email-box {
    height: auto;
    padding: 20px;
  }

  .email-box-heading {
    font-size: 15px;
  }

  .email-form-inner-stuff-lcm input {
    width: 100%;
  }

  .email-btn-main {
    width: 100%;
  }

  .or-main-lw {
    width: 100%;
  }

  .noaccountpara {
    width: 100%;
    font-size: 14px;
    padding: 10px;
  }

  .email-box-Image {
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }

  .email-box-Image h3 {
    font-size: 24px;
  }

  .email-box-Image img {
    width: 100%;
    height: auto;
  }

  .forgot-Password-txt {
    padding-right: 20px;
  }
}

/* For small mobile screens (max-width: 480px) */
@media screen and (max-width: 480px) {
  .email-box {
    padding: 15px;
  }

  .email-box-heading {
    font-size: 14px;
  }

  .email-form-inner-stuff-lcm input {
    padding: 10px;
    font-size: 14px;
  }

  .email-btn-main button {
    height: 40px;
    font-size: 14px;
  }

  .noaccountpara {
    font-size: 13px;
  }

  .email-box-Image h3 {
    font-size: 20px;
  }

  .forgot-Password-txt {
    font-size: 11px;
  }
}

.mobileScreen-email-box-Image {
  z-index: 99;
  text-align: center;
  padding: 20px;
  background-color: #E8EBFF;
  max-width: 100%;
  max-height: 90%;
  justify-content: center;
  transition: background-color 1s ease-in-out;
}

.mobileScreen-email-box-Image h3 {
  font-size: 25px;
  font-weight: 600;
  font-family: "Quicksand";
  color: #394b42;
  margin-top: 70px;
}

.mobileScreen-email-form-inner-stuff-lcm {
  margin-top: 20px;
}

.mobileScreen-email-form-inner-stuff-lcm input {
  width: 100%;
  border-radius: 50px;
  background-color: #fffcf8;
  box-shadow: 1px 1px 10px 1px #ececec;
  border: 0px;
  padding: 12px;
  padding-left: 18px;
  outline: none;
}
.events-page{
    padding:0 30px;
    width:100%;
    font-family: "Quicksand", sans-serif;
    display: flex;
    flex-direction: column;
}
.events-div{
    display:flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
}
.event-info{
    box-shadow: 0px 0px 20px 0px #394B424D;
    background: #DCF6E9;
    width:100%;
    max-width: 1203px;
    height:auto;
    padding: 20px;
    border-radius: 32px;
    align-self: center;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
}
.event-info .left{
    width:40%;
    margin-left: 30px;
}
.event-info .left .name{
    font-size: 2em;
    font-weight: 600;
    margin:0;
}
.event-info .left .by{
    font-size: 1.3em;
    font-weight: 600;
}
.event-info .left .date-time{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.3em;
    font-weight: 500;
}
.event-info .left .desc{
    font-size: 1.25em;
    font-weight: 500;
    text-align: justify;
}
.event-info .left .join-btn{
    margin-top: 40px;
    background: #49AF7C;
    border-radius: 32px;
    padding: 10px;
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
    /* margin-left: auto;
    margin-right: auto; */
    font-size: 1.25em;
    font-weight: 600;
}
.event-info .left .join-btn p{
    margin: 0;
}
.event-info .right{
    box-shadow: 0px 0px 10px 0px #394B421A;
    background: #F8FFFB;
    width: 50%;
    height: 100%;
    min-height: 300px;
    border-radius: 16px;
    padding: 20px;
    margin-left: 60px;
    display: flex;
    align-items: center;
}
.event-info .right img{
    width:100%;
    height:100%;
    object-fit: contain;
}
.events-head{
    font-weight:600;
    font-size:2em;    
}
.events-nav{
    display: flex;
    align-items: center;
    overflow-x: hidden;
    scroll-behavior: smooth;
    width: 90%;
    white-space: nowrap;
}
.events-nav::-webkit-scrollbar {
    display: none;
}
.event-div{
    background: #FFE9AA;
    width: 292.03px;
    height: 270.62px;
    border-radius: 32px;
    padding: 20px;
    text-align: center;
    margin-right: 20px;
    font-size: 1.25em;
    font-weight: 500;
    transition: transform 0.3s ease, opacity 0.3s ease;
}
.event-div.eventActive{
    box-shadow: 0px 0px 10px 0px #394B424D;
    background: #DCF6E9;
    width: 382px;
    height: 340px;
    font-size: 1.4em;
    font-weight: 600;
}
.event-thumbnail{
    box-shadow: 0px 0px 10px 0px #394B421A;
    background: #F8FFFB;
    width: 242.69px;
    height: 178.36px;
    border-radius: 16px;
    padding: 20px;
    margin-bottom: 20px;
}
.event-div.eventActive .event-thumbnail{
    width: 317.47px;
    height: 233.32px;
}
.event-thumbnail img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.mobile-events{
    display: none;
}
@media screen and (max-width: 1050px) {
    .events-page{
        padding-top: 80px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .event-info.desktop{
        display: none;
    }
    .events-head{
        font-size: 1.5em;
    }
    .mobile-events{
        display: block;
    }
    .events-div{
        display: none;
    }
    .event-info{
        flex-direction: column-reverse;
        background: #FFE9AA;
        padding: 25px 20px 20px 20px;
    }
    .event-info .left{
        width:100%;
        margin:0;
    }
    .event-info .right{
        width:100%;
        margin:0;
        margin-bottom: 20px;
        background: #F8FFFB;
        height: 280px;
        min-height: unset;
    }
    .info-top{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .info-top .name{
        font-size: 1.5em !important;
    }
    .info-top .date{
        font-size: 1.28em;
        font-weight: 500;
        margin:0;
    }
    .event-info.expanded{
        background: #DCF6E9;
    }
    .expand-icon{
        width:45px;
        height:45px;
        border-radius: 50%;
        background: #49AF7C;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
@media screen and (max-width: 500px) {
    .event-info .right{
        height: 220px;
        min-height: unset;
    }
    .mobile-events.ongoing{
        display: flex;
        overflow-x: scroll;
    }
}
.left-apm {
  flex-grow: 1;
  overflow-y: auto;
}

.right-apm {
  width: 50%;
  max-width: 780px;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.popup-passessment{
  width: 50vw;
  font-size: 1000;
}

.fp-tableCell {
  vertical-align: top !important;
}

.halfcontainerquiz {
  width: 50%;
  height: 100vh;
  padding-top: 20px;
  padding-left: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #FFFCF8 !important;
  transform: translateY(-60px);
}

.title-hcqs {
  font-size: 35px;
  font-weight: 700;
  line-height: 41px;
  margin-bottom: 20px;
  padding-top: 20px;
  color: #394B42;
}

.subtitle-hcqs {
  margin-bottom: 20px !important;
  font-size: 25px;
  font-weight: 600;
  color: #394B42;
}

.quizOptions{
  font-size: 15px;
  font-weight: 400;
  color: #394B42;
}

.right-apm {
  position: fixed;
  right: 0px;
  bottom: 0px;
}

.btns-quiz-updown {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}

.btns-quiz-updown button {
  border: none;
  outline: none;
  width: 160px;
  height: 50px;
  margin: 0px 5px;
  border-radius: 50px;
  color: #FFFCF8;
  background-color: #FF8831;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btns-quiz-updown button.disabled {
  background-color: #ececec;
  color: black;
  cursor: not-allowed;
}

.sectionpageassessmnet{
  height: 100vh !important;
  background-color: #FFFCF8;
}

.btns-quiz-updown button:first-child {
  background-color: #C4EED9;
}

.btns-quiz-updown button:not(:first-child) {
  background-color: rgba(73, 175, 124, 0.4);
}

.btns-quiz-updown button:not(.disabled):not(:first-child) {
  background-color: #49AF7C;
}

.select-box-lcb {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 50px;
  cursor: pointer;
  transition: 0.3s;
  background: #FFFCF8;
  width: 95%;
  margin-bottom: 10px;
  height: 50px !important;
  box-shadow: 1px 1px 10px 1px #ececec;
}

.select-box-lcb.selected {
  background-color: #fadd8a !important;
  color: #FFFCF8 !important;
}

.select-box-lcb:hover {
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
}

.select-box-lcb .top-sblcb {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.select-box-lcb .grayboxtsb,
.select-box-lcb .checkboxtsb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  /* border: 2px solid #ccc; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-box-lcb .checkboxtsb {
  background-color: #4caf50;
  border: 2px solid #4caf50;
}

.select-box-lcb .topselectedsb {
  background-color: #4caf50 !important;
  color: #FFFCF8 !important;
  border: 2px solid #4caf50 !important;
}

.top-sblcb {
  display: flex;
  align-items: center;
  padding: 12px;
  width: 100%;
  border-radius: 10px;
  color: #394B42;
  transition: all 0.3s ease-in-out;
}

.top-sblcb.topselectedsb {
  background-color: #49AF7C;
}

.checkboxtsb {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #49AF7C;
  color: #FFFCF8;
  margin-right: 10px;
}

.grayboxtsb {
  width: 24px;
  height: 24px;
  border: 2px solid #ccc;
  border-radius: 50%;
  margin-right: 10px;
}

.quizOptions {
  font-size: 16px;
  font-weight: 500;
  flex-grow: 1;
  color: #333;
  text-align: left;
  padding-top: 10px;
}

.topselectedsb .quizOptions {
  color: #49AF7C;
  color: #394B42 !important;
}

.select-box-lcb.selected .quizOptions {
  color: #394B42 !important;
  margin-top: 4px;
}

.next-btn {
  background-color: rgba(73, 175, 124, 0.4) !important;
  color: #999;
  cursor: not-allowed;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  font-weight: 700;
}

.next-btn.active {
  background-color: #49AF7C !important;
  color: #FFFCF8 !important;
  cursor: pointer;
  font-weight: 700;
}

.sectionpageassessmnet .section .fp-overflow {
  overflow: hidden;
}


.popup-spassessment-cont {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(57, 75, 66, 0.3);
}

.popup-spassessment {
  height: 300px;
  background-color: #FFFCF8;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: all .3s ease-in-out;
  max-width: 500px;
}

.popup-spassessment p {
  color: #333;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 40px;
  margin-left:10px;
  margin-right:10px;
}

.buttons-pspa {
  display: flex;
}

.buttons-pspa button {
  border: none;
  outline: none;
  width: 180px;
  height: 50px;
  margin: 0px 5px;
  border-radius: 50px;
  color: #394B42;
  background-color: #C4EED9;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Quicksand';
  font-weight: 500;
}

.buttons-pspa button:last-child {
  background-color: #49AF7C;
  margin-left: 30px;
  color: #FFFCF8;
  font-family: 'Quicksand';
  font-weight: 600;
}

@media screen and (min-width: 768px) and (max-width: 1500px) {
  .assessment-page-main {
    position: static;
  }
  .halfcontainerquiz {
    padding-left: 20px;
    padding-right: 20px;
    background-color: #FFFCF8;
  }
  .sectionpageassessmnet .section {
  }
  .sectionpageassessmnet .headerbookings {
    position: fixed !important;
  }
  .btns-quiz-updown button {
    width: 150px;
  }
  .title-hcqs {
    font-size: 22px;
    margin-bottom: 10px;
  }
  .subtitle-hcqs {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .btns-quiz-updown {
    margin-top: 15px;
  }
  .popup-spassessment {
    width: 600px;
	max-width: 600px;
  }
}

@media screen and (max-width: 1050px) {
  .right-apm {
    height: 180px;
    width: 100%;
	  top:62px;
    display: none;
  }
  .assessment-page-main {
    position: static;
  }
  .halfcontainerquiz {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #FFFCF8;
  }
  .sectionpageassessmnet .section {
    height: calc(100vh - 60px);
  }
  .sectionpageassessmnet .headerbookings {
    position: fixed !important;
  }
  .btns-quiz-updown button {
    width: 150px;
  }
  .title-hcqs {
    font-size: 22px;
    margin-bottom: 10px;
  }
  .subtitle-hcqs {
    font-size: 18px;
  }
  .btns-quiz-updown {
    margin-top: 15px;
  }
  .popup-spassessment {
	width: 95%;
	max-width: 600px;
  }
  .buttons-pspa {
    display: flex;
	width:100%;
  }
}
.fmt-container-bselection {
  width: 100%;
  background-color: #fffcf8;
  min-width: 100vh;
  position: relative;
}
.fmt-container-bselection .backButton {
  position: absolute;
  top: 5%;
  height: 40px;
  width: 40px;
  background-color: #dcf6e9;
  border-radius: 50%;
  border: 0px;
}

.fmt-container-bselection h3 {
  font-size: 25px;
  text-align: center;
  font-weight: 600;
  font-family: "Quicksand";
  color: #394b42;
  padding-top: 20px;
}

.bookingselection {
  padding-bottom: 80px;
}

.fmt-left-cbselection {
  width: 690px;
  padding-top: 10px;
  background-color: #fffcf8;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fmt-right-cbselection {
  width: 495px;
  height: 260px;
  max-width: 100%;
  max-height: 90%;
  margin-top: 90px;
  margin-left: 50px;
  z-index: 99;
  background-color: #fffcf8;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.fmt-right-cbselection button {
  width: 250px;
  height: 60px;
  border: none;
  outline: none;
  border-radius: 40px;
  background: linear-gradient(225.34deg, #ff7f7f 0%, #ff7fb7 98.93%);
  color: #fcf2d8;
  font-size: 1.6em;
  margin-top: 60px;
}

.fmt-select-box-lcb {
  width: 100%;
  max-width: 90%;
  margin-bottom: 25px;
}

.fmt-select-box-lcb p {
  font-size: 1em;
  color: #394b42;
  font-weight: 500;
  font-family: "Quicksand";
}

.input-group {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

.input-group .fmt-select-box-lcb {
  width: 48%;
}

.fmt-top-sblcb {
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  border-radius: 50px;
  background-color: #fffcf8;
  box-shadow: 1px 1px 10px 1px #ececec;
  border: 0px;
  outline: none;
}

.fmt-top-sblcb p {
  flex-grow: 1;
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 1.4em;
}

.fmt-top-sblcb input {
  flex-grow: 1;
  padding-right: 20px;
  -moz-appearance: textfield;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 1.3em;
  color: #394b42;
  font-weight: 500;
  font-family: "Quicksand";
}

.fmt-topselectedsb input,
.fmt-top-sblcb:hover input {
  color: #394b42;
}

.fmt-top-sblcb input::placeholder {
  color: #394b42;
}

.fmt-top-sblcb input::-webkit-outer-spin-button,
.fmt-top-sblcb input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mobile-div {
  display: -webkit-box;
}

.grayboxtsb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #e8fdf2;
  margin-right: 15px;
}

.fmt-dropdowntsb {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #DCF6E9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fmt-dropdowntsb .MuiSvgIcon-root {
  font-size: 15px;
  color: black;
}

.therapy-medication-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 90%;
}

.fmt-mobile-input-wrapper {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.fmt-mobile-input-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.country-code-dropdown {
  width: 13%;
}

.fmt-mobile-number {
  width: 100%;
}

.fmt-bottom-sblcb {
  background-color: #fffcf8;
  box-shadow: 2px 2px 6px 2px #ececec;
  position: absolute;
  border-radius: 8px;
  z-index: 999;
  max-height: 300px;
  overflow-y: auto;
}

.fmt-bottom-sblcb::-webkit-scrollbar {
  width: 5px;
}

/* Track */

.fmt-bottom-sblcb::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */

.fmt-bottom-sblcb::-webkit-scrollbar-thumb {
  background: #f9ce55;
}

/* Handle on hover */

.fmt-bottom-sblcb::-webkit-scrollbar-thumb:hover {
  background: #f9ce55;
}

.fmt-bottom-sblcb ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.fmt-bottom-sblcb ul li {
  padding: 8px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}

.fmt-bottom-sblcb ul li:hover {
  background-color: #f5f5f5;
}

.fmt-topselectedsb,
.fmt-top-sblcb:hover {
  background-color: #e8fdf2;
}

.fmt-topselectedsb p,
.fmt-top-sblcb:hover p {
  color: #394b42;
  font-weight: 500;
  font-size: 1.2em;
  font-family: "Quicksand";
}

.checkboxtsb {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #e8fdf2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.checkboxtsb .MuiSvgIcon-root {
  font-size: 16px;
  color: #394b42;
}

.fmt-select-box-lcb.fmt-textareaboxsbl {
  width: 610px;
}

.fmt-select-box-lcb.fmt-textareaboxsbl .fmt-top-sblcb {
  padding-top: 10px;
  height: 120px;
  align-items: flex-start;
}

.fmt-top-sblcb textarea {
  flex-grow: 1;
  background-color: transparent;
  border: none;
  outline: none;
  color: #394b42;
  font-weight: 500;
  font-size: 16px;
  font-family: "Quicksand";
  height: 90%;
  resize: none;
  padding: 10px;
}

.fmt-topselectedsb textarea,
.fmt-top-sblcb:hover textarea {
  color: #394b42;
}

.fmt-top-sblcb textarea::placeholder {
  color: #394b42;
}

.fmt-listinputbsb {
  width: 100%;
}

.fmt-listinputbsb input {
  padding: 10px;
  width: 100%;
  border: 1px solid #ececec;
  background-color: #ececec;
  outline: none;
  border-radius: 5px;
}

.timeslotdisabled {
  cursor: not-allowed !important;
  background: rgba(73, 175, 124, 0.4) !important;
  color: #FFFCF880 !important;
}

.fmt-btn-Continuemain {
  width: 580px;
  margin-top: 25px;
  margin-bottom: 25px;
}

.fmt-btn-Continuemain button {
  width: 100%;
  height: 45px;
  border-radius: 50px;
  background-color: #49af7c;
  border: none;
  outline: none;
  color: white;
  font-weight: 500;
  font-size: 15px;
  font-family: "Quicksand";
}

.fmt-progress-container {
  position: relative;
  width: 110%;
  max-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  height: 2vh;
  margin-left: -30px;
}

.progress-line {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100vw;
  height: 4px;
  background-color: #4caf50;
  z-index: 1;
}

.progress-steps {
  display: flex;
  justify-content: space-between;
  width: 60%;
  max-width: 800px;
  position: relative;
}

.progress-step {
  width: 30px;
  height: 30px;
  background-color: white;
  border: 3px solid #ccc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  z-index: 2;
}

.completed {
  background: #49af7c;
  color: white;
  border-color: #49af7c;
}

.active {
  color: white;
}

@keyframes expandLine {
  from {
    width: 0;
  }
  to {
    width: 100vw;
  }
}

@media screen and (max-width: 1050px) {
  .fmt-left-cbselection {
    padding-left: 0px;
    width: 95%;
    margin: 0px auto;
  }
  .fmt-select-box-lcb {
    width: 100%;
  }
  .fmt-select-box-lcb.fmt-textareaboxsbl {
    width: 100%;
  }
  .fmt-right-cbselection {
    width: 100%;
    min-width: 0px;
  }
  .fmt-right-cbselection img {
    width: 100%;
  }
  .input-group {
    flex-direction: column;
  }
  .input-group .fmt-select-box-lcb {
    width: 100%;
  }
}

.shapewholedesktop {
  width: 100%;
  position: absolute;
  top: 32%;
}

.signout-modal {
  font-family: "Quicksand", sans-serif;
  z-index: 999999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fffcf8;
  width: 360px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 35px;
}
.signout-head {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}
.sbtns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  width: 80%;
  align-self: center;
}
.signout-btn {
  background: #49af7c;
  color: #fffcf8;
  font-size: 15px;
  font-weight: 500;
  width: 40%;
  height: 40px;
  border-radius: 32px;
  border: none;
  outline: none;
}
.signout-btn.no {
  background: #c4eed9;
  color: #394b42;
  font-weight: 500;
}

.fmt-mobile-container-bselection {
  width: 100%;
  background-color: #fffcf8;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fmt-mobile-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
}

.fmt-mobile-container-bselection h3 {
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  font-family: "Quicksand";
  color: #394b42;
  padding-top: 70px;
  padding-left: 45px;
}

.fmt-mobile-progress-container {
  position: relative;
  width: 100%;
  max-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  height: 2vh;
}

.fmt-mobile-progress-step {
  width: 20px;
  height: 20px;
  background-color: white;
  border: 2px solid #ccc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  z-index: 2;
  margin-top: 2px;
}

.fmt-mobile-btn-Continuemain {
  width: 90%;
  margin-top: 25px;
  margin-bottom: 25px;
}

.fmt-mobile-btn-Continuemain button {
  width: 100%;
  height: 45px;
  border-radius: 50px;
  background-color: #49af7c;
  border: none;
  outline: none;
  color: white;
  font-weight: 500;
  font-size: 15px;
  font-family: "Quicksand";
}

.fmt-mobileScreen-input-wrapper,
.fmt-mobileScreen-input-container {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.therapy-medication-mobile-container {
  display: flex;
  justify-content: space-between;
  width: 90%;
}

.fmt-mobile-backButton {
  position: relative;
  margin-top: 75px;
  height: 18px;
  width: 18px;
  background-color: #dcf6e9;
  border-radius: 50%;
  border: 0px;
}
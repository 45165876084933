.profile-menu{
    position:fixed;
    top:8%;
    right:4%;
    border-radius: 32px;
    width:300px;
    background: #fefdfb;
    padding: 20px;
    font-family: "Quicksand", sans-serif;
    box-shadow: 0px 0px 10px 0px #26342d1a;
}
.profile-menu .profile-back-btn {
    display: none;
}
.profile-menu .avatar {
    display: none;
    width: 100px;
    height: 100px;
    object-fit: contain;
    margin: 0px auto;
}
.profile-menu a {
    text-decoration: none;
    color: inherit;
}
.pmenu-top{
    font-size: 1.5em;
    font-weight: 600;
    border-bottom: 1px solid #394B4266;
}
.menu-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #DCF6E9;
    border: none;
    outline: none;
    width:100%;
    font-size: 1rem;
    font-weight: 500;
    margin-top: 15px;
    border-radius: 32px;
    padding: 10px 15px;
}
.menu-item p{
    margin: 0;
}
.menu-item.signout{
    margin-top: 40px;
    background: #FFFCF8;
    box-shadow: 0px 0px 10px 0px #394B421A;
}
.notification-div{
    height:90%;
    overflow-y: auto;
    padding-right: 10px;
}
.notification-div::-webkit-scrollbar-thumb{
    background: black;
}
.noti-div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
    font-weight: 500;
    border-bottom: 1px solid #394B4266
}
.noti-div p{
    margin:0;
}
.noti-div img{
    width:40px;
    height:40px;
    border-radius: 50%;
    margin:0 10px;
}
.noti-icondiv{
    margin:0 10px;
    width:40px;
    height:40px;
    border-radius: 50%;
    background: #49AF7C;
    display: flex;
    align-items: center;
    justify-content: center;
}
.notification-top{
    display: none;
}
.noti-right{
    width:85%;
}
.noti-date{
    font-size: 1em;
    font-weight: 400;
}
.noti-message{
    font-size: 1.15em;
    font-weight: 500;
}
@media screen and (max-width: 1050px){
    .profile-menu{
        z-index: 999999999;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        padding-top: 70px;
        overflow-y: auto;
    }
    .profile-menu .profile-back-btn {
        border: 0px;
        background-color: rgba(220, 246, 233, 1);
        padding: 5px;
        border-radius: 50px;
        display: block;
    }
    .notification-top{
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 1.5em;
        margin-bottom: 20px;
        width:100%;
    }
    .notification-top p{
        margin:0;
        margin-left:8%;
    }
    .noti-back{
        width:40px;
        height:40px;
        border-radius: 50%;
        padding-left: 10px;
        background: #E0F3E0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .profile-menu .avatar {
        display: block;
    }
    .pmenu-top p {
        text-align: center;
    }
}
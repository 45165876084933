.assessments-page {
    padding:0 30px;
    width:100%;
    font-family: "Quicksand", sans-serif;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.assessments-heading {
    display: flex;
    align-items: center;
    gap: 25px;
    position: relative;
}
.assessments-heading button {
    width: 40px;
    height: 40px;
    border: 0px;
    border-radius: 50%;
    background-color: #DCF6E9;
}
.assessments-heading h1 {
    font-size: 22px;
    font-weight: 600;
    margin: 0;
}
.assessments-searchbar {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-radius: 100px;
    gap: 15px;
    max-width: 500px;
    background-color: #DCF6E9;
}
.assessments-searchbar input {
    outline: none;
    border: 0px;
    background: transparent;
    font-weight: 500;
    flex: 1;
}
.assessments-container {
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
.assessment-card {
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    width: 200px;
    box-shadow: 0px 0px 10px 0px rgba(57, 75, 66, 0.1);
    border-radius: 20px;
    line-height: normal;
}
.assessment-card-content h3 {
    font-size: 18px;
    font-weight: 600;
}
.assessment-card-btn {
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    padding: 15px;
    text-align: center;
    color: #fff;
    background-color: #49AF7C;
    border-radius: 100px;
}
.assessment-card-btn:hover {
    color: #fff;
}


@media screen and (max-width: 1050px){
    .assessments-page {
        padding: 90px 15px 50px;
    }
    .assessment-tags {
        flex-wrap: wrap;
    }
    .assessments-heading h1 {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .assessment-card {
        flex: 1;
        max-width: 200px;
        min-width: 150px;
        width: auto;
    }
    .assessment-card-btn {
        text-decoration: none;
        font-size: 14px;
        font-weight: 600;
        padding: 10px;
        text-align: center;
        color: #fff;
        background-color: #49AF7C;
        border-radius: 100px;
    }
 }
.challenges-page{
    padding:0 30px;
    width:100%;
    min-height: 70vh;
    position: relative;
}

.challenges-page .challenges-error-msg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 500;
}

.challenges-wrapper {
    position: relative;
    width: 100%;
}
.nav-button {
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    z-index: 2;
    border-radius: 50px;
}
.nav-button.left {
    left: 10px;
}
.nav-button.right {
    right: 10px;
}
.nav-button:hover {
    background-color: rgba(0, 0, 0, 0.6);
}

.challenges-top{
    display:flex;
    flex-wrap: wrap;
    gap:32px;
    width:100%;
    margin-bottom: 30px;
    font-family: "Quicksand", sans-serif;
}
.challenges-tleft{
    display: flex;
    flex-direction: column;
    width:50%;
    max-width: 628px;
}
.challenge-container{
    width: 100%;
    max-width: 628px;
    border-radius: 32px;
    position: relative;
}
.challenge-icontainer{
    width: 100%;
    height: 350px;
    border-radius: 32px 32px 0px 0;
    /* background: #FADD8A; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}
.challenge-icontainer img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.challenge-name{
    background: transparent;
    width: 100%;
    height: 70px;
    border-radius: 0px 0px 32px 32px;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
}
.challenge-name .value{
    font-size: 1.25em;
    font-weight: 500;
    margin: 0 20px;
}
.challenge-name .head{
    font-size: 1.4em;
    font-weight: 600;
    color: #394B42;
}
.challenge-users{
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    gap: 5px;
    align-items: center;
}
.challenge-users img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.challenge-users .user-div{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    font-weight: 500;
    background: #FFFCF8;
    margin-left: -15px !important;
}
.user-div p{
    margin: 0;
}
.challenge-info{
    width:100%;
    height:55px;
    border-radius: 71px;
    background: #DCF6E9;
    margin-top:20px;
    display: flex;
    align-items: center;
    padding: 0 20px;
}
.challenge-info img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.challenge-info .days{
    width:25%;
    text-align: center;
    display: block !important;
}
.challenge-info .days p{
    margin-top: 0;
    font-size: 1.25em;
    font-weight: 500;
}
.challenge-info .users{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    margin:0 10px;
    padding-right: 10px;
    width:50%;
    border-left: 1px solid #394B42;
    border-right: 1px solid #394B42;
}
.challenge-info .users,.coins{
    align-items: center;
    display: flex;
}
.challenge-info .coins{
    width:25%;
    font-size: 1.25em;
    font-weight: 500;
    justify-content: center;
}
.challenge-info .coins img{
    width:40px;
    height:40px;
    margin-right: 10px;
}
.challenge-info .user-div{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    font-weight: 500;
    background: #C4EED9;
    margin-left: -10px !important;
}
.challenge-info p{
    margin: 0;
}
.challenges-bottom{
    font-family: "Quicksand", sans-serif;
    width:100%;
    display: flex;
    flex-direction: column;
}
.start-cha{
    width:100%;
    height:55px;
    border-radius: 32px;
    background: #49AF7C;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color:white;
    border:none;
    outline:none;
    font-size: 1.28em;
    font-weight: 600;
}
.leaderboard-container{
    width:100%;
    max-width: 1161px;
    border-radius: 32px;
    height:auto;
    box-shadow: 0px 0px 10px 0px #394B421A;
    margin-top: 32px;
}
.user-leaderboard{
    width:100%;
    max-width: 1161px;
    border-radius: 32px;
    padding: 10px 30px;
    font-family: "Quicksand", sans-serif;
    height:auto;
    box-shadow: 0px 0px 10px 0px #394B421A;
    background: #FFFCF8;
}
.user-leaderboard.blurred{
    filter: blur(8px);
    -webkit-filter: blur(8px);
}
.user-leaderboard.viewall{
    height:80%;
    width:70%;
    max-height: 700px;
    max-width: 1216px;
    z-index: 999999;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.user-leaderboard.viewall .leaderboard-top{
    height:10%;
}
.user-leaderboard.viewall .ltable-div{
    height:85%;
}
.user-leaderboard.viewall .ltable-div .ltable-head{
    height:5%;
}
.user-leaderboard.viewall .ltable-div .ltable-body{
    height:85%;
    overflow-y: scroll;
}
.leaderboard-container.viewall{
    width:100%;
    max-width: 100%;
    height:100%;
    background-color: rgba(0, 0, 0, 0.352);
    z-index: 999999999;
    position: fixed;
    top:0px;
    left:0;
    border-radius: 0;
    margin:0;
}
.leaderboard-container.blurred{
    position:relative;
}
.lock-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}
.user-leaderboard p{
    margin: 0;
}
.leaderboard-top{
    display: flex;
    align-items: center;
    margin: 15px 0px;
}
.leaderboard-tleft{
    width:100%;
    display: flex;
    align-items: center;
    font-size: 1.5em;
    font-weight: 500;
    justify-content: center;
}
.leaderboard-tleft p{
    margin-left:5px;
    margin-right: 5px;
}
.ltable-div{
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 1.2em;
    font-weight: 500;
    height:70%;
}
.ltable-body{
    height:95%;
    overflow: hidden;
    width:100%;
    margin-top: 15px;
}
.ltable-body::-webkit-scrollbar-thumb{
    background: #394B42;
}
.ltable-head{
    display: flex;
}
.ltable-box{
    display: flex;
    margin:15px 0;
    width:100%;
}
.ltable-box .rank{
    width:10%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ltable-box .rank img{
    height:40px;
}
.ltable-box .name{
    display: flex;
    align-items: center;
    width:20%;
}
.ltable-box .name img{
    width:50px;
    height:50px;
    margin-right: 10px;
}
.ltable-box .asteps{
    width: 20%;
    text-align: center;
}
.ltable-box .asteps p{
    margin-top: 10px;
}
.ltable-box .steps{
    width:20%;
    text-align: center;
}
.ltable-box .steps p{
    margin-top: 10px;
}
.ltable-box .days{
    width:20%;
    text-align: center;
}
.ltable-box .days p{
    margin-top: 10px;
}
.ltable-box .team{
    width:20%;
    text-align: center;
}
.ltable-box .team p{
    margin-top: 10px;
}
.ltable-head .hrank{
    width:10%;
    text-align: center;
}
.ltable-head .hname{
    width:20%;
    text-align: center;
    padding-left: 40px;
}
.ltable-head .hasteps{
    width:20%;
    text-align: center;
}
.ltable-head .hsteps{
    width:20%;
    text-align: center;
}
.ltable-head .hdays{
    width:20%;
    text-align: center;
}
.ltable-head .hteam{
    width:21%;
    text-align: center;
}
.view-uleaderboard{
    font-size: 1.2em;
    font-weight: 500;
    color:#49AF7C;
    text-align: right;
    white-space: nowrap;
    text-decoration: underline;
    cursor: pointer;
}
.close-uleaderboard{
    width:40px;
    height:40px;
    border-radius: 50%;
    background: #DCF6E9;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.dailygoal-div{
    display: flex;
    background: #DCF6E9;
    width:auto;
    height:auto;
    margin-left: auto;
    margin-right: auto;
    border-radius: 71px;
    padding: 20px 20px;
}
.dailygoal-div p{
    margin:0;
}
.dailygoal-left{
    display: flex;
    align-items: center;
    width:45%;
}
.goalprogress-div{
    display: flex;
    align-items: center;
    justify-content: center;
}
.goalprogress-div .ant-progress-circle .ant-progress-inner{
    width:85px !important;
    height:85px !important;
}
.goalprogress-div .ant-progress-circle .ant-progress-text{
    font-size: 1em;
    font-weight: 600;
}
.goalprogress-div .ant-progress-circle-trail{
    stroke-width:8px;
}
.goalprogress-div .ant-progress-circle{
    animation: zoom-in-zoom-out 1s ease forwards;
}
.dailygoal-head{
    font-size: 1.3em;
    font-weight: 600;
    margin-left: 10px !important;
}
.dailygoal-divide{
    border: 1px solid #394B4266;
    height: 100px;
    width:1px;
    margin: 0 20px;
}
.dailygoal-right{
    width:45%;
    display: flex;
    flex-direction: column;
}
.dailygoal-right .head{
    font-size: 1.25em;
    font-weight: 500;
}
.target-div{
    display: flex;
    align-items: center;
}
.target-value{
    background: #49AF7C;
    height:10px;
    border-radius: 8px;
}
.target{
    background: #394B4226;
    width:100%;
    height:10px;
    border-radius: 8px;
    margin-right: 8px;
}
.dailygoal-right .target-txt{
    font-size: 1.18em;
    font-weight: 500;
    text-align: right;
}
.challenges-tright{
    width:45%;
    display: flex;
    flex-direction: column;
    max-width: 556px;
}
.win-divs{
    width:100%;
    height:420px;
    border-radius: 32px;
    box-shadow: 0px 0px 10px 0px #394B421A;
    background: #FFFCF8;
    padding: 20px;
}
.win-divs .head{
    font-size: 1.2em;
    font-weight: 500;
}
.win-divs .value{
    font-size: 1.2em;
    font-weight: 400;
}
.win-divs .meter{
    background: #E5E5E5;
    width:100%;
    border-radius: 25px;
    height:10px
}
.win-divs .meter-value{
    background: #49AF7C;
    border-radius: 8px;
    height:100%;
}
.win-div{
    margin: 40px 0;
}
.dailygoal-div.mobile{
    display: none;
}
.Challenge-modal-content {
    position: relative;
    background-color: #DCF6E9;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 25px;
    padding-bottom: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    max-width: 90%;
    width: 400px;
    max-height: 80vh;
    overflow-y: auto;
    text-align: center;
}
.close-icon {
    position: absolute;
    width: 20px;
    height: 20px;
    cursor: pointer;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
}
.image-row {
    display: flex;
    justify-content: center;
    gap: 15px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* flex-direction: column; */
}
.Challenge-modal-content p{
    font-size: 1.2em;
    font-weight: 600;
    color:#394B42;
    font-family: 'Quicksand';
}

.store-icon {
    width: 130px;
    height: 40px;
    border-radius: 10px;
}

.lottieAnimation {
    height:350px;
    width: 350px;
}


@media screen and (max-width: 1050px){
    .challenges-page{
        margin-top: 60px;
        padding: 20px;
        margin-bottom: 40px;
    }
    .challenges-top{
        flex-direction: column;
        align-items: center;
        gap:70px;
        margin-bottom: 0;
    }
    .challenge-name{
        margin-top: -25px;
    }
    .leaderboard-tleft img{
        width:35px;
        height:35px;
    }
    .leaderboard-top{
        justify-content: space-between;
    }
    .leaderboard-tleft{
        width:auto;
    }
    .challenges-tleft{
        width:100%;
        gap:80px;
    }
    .challenges-tright{
        width:100%;
        margin-top: 20px;
    }
    .challenge-container{
        height:300px;
        max-width: 500px;
        align-self: center;
    }
    .challenge-icontainer{
        height:100%;
        /* padding-top: 50px; */
    }
    .leaderboard-container,.user-leaderboard{
        height:auto;
    }
    .user-leaderboard{
        padding: 10px 20px;
    }
    .goalprogress-div{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .goalprogress-div .ant-progress-circle .ant-progress-inner{
        width:180px !important;
        height:180px !important;
    }
    .goalprogress-div .ant-progress-circle .ant-progress-text{
        font-size: 1em;
        font-weight: 400;
        color:#394B42;
        margin-top: -5px;
    }
    .goalprogress-div .ant-progress-circle .ant-progress-text .goal-completion{
        font-weight: 500;
    }
    .goalprogress-div .ant-progress-circle .ant-progress-text .target-steps{
        font-size: 1.3em;
        font-weight: 500;
    }
    .goalprogress-div .ant-progress-circle-trail{
        stroke-width:5px;
    }
    .goalprogress-div .ant-progress-circle{
        animation: zoom-in-zoom-out 1s ease forwards;
    }
    .challenge-info{
        padding: 10px 20px;
        height:57px;
    }
    .view-uleaderboard{
        color: #394B42;
        height:32px;
        background: #C4EED9;
        border-radius: 32px;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .start-cha{
        width:50%;
        margin-left:auto;
        margin-right: auto;
        cursor: pointer;
    }
    .win-divs .value{
        margin:0;
    }
    .win-divs .meter{
        height:10px;
    }
    .win-divs .head{
        margin-left: 20px !important;
        margin-bottom: 10px !important;
        margin-top: -90px;
    }
    .win-div{
        margin-top: 0;
        margin-bottom: 20px;
    }
    .win-divs{
        background: none;
        box-shadow: none;
        padding: 20px 0;
        height:auto;
    }
    .win-values{
        box-shadow: 0px 0px 10px 0px #394B421A;
        background: #FFFCF8;
        border-radius: 32px;
        padding: 20px;
    }
    .challenge-info .days{
        width:22%;
        margin-left: 0 !important;
    }
    .challenge-info .users{
        width:55%;
    }
    .challenge-info .coins{
        width:22%;
    }
    .dailygoal-div{
        display: none;
    }
    .dailygoal-div.mobile{
        display: block;
        background: none;
        padding: 0;
    }
    .dailygoal-div.mobile .head{
        font-size: 1.3em;
        font-weight: 500;
        margin-bottom: 10px;
    }
    .dailygoal-div.mobile .value{
        color:#49AF7C;
        font-weight: 600;
        font-size: 1.4em;
    }
    .ltable-head{
        display: none;
    }
    .ltable-box .rank{
        width:20%;
    }
    .ltable-box .name{
        width:40%;
    }
    .ltable-box .steps{
        width:40%;
    }
    .ltable-box .asteps, .days, .team {
        display: none;
    }
    .user-leaderboard.viewall .ltable-div .ltable-body{
        height:96%;
        overflow-y: scroll;
    }
    .Challenge-modal-content {
        width: 90%;
        max-width: 400px;
        padding: 15px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .modal-close {
        font-size: 18px;
    }
    .lottieAnimation{
        height:280px;
        width: 350px;
        border-radius: 25px;
    }
}
@media screen and (max-width: 499px){
    .challenge-icontainer img{
        width:70%;
        height:100%;
    }
    .user-leaderboard.viewall{
        width:90%;
        padding: 10px;
    }
    .Challenge-modal-content {
        width: 90%;
        max-width: 350px;
        padding: 22px;
        margin-left: 15px;
        margin-right: 15px;
    }
    .modal-close {
      top: 5px;
      right: 5px;
      font-size: 16px;
    }
}

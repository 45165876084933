.user-modal{
    width:100%;
    height:100%;
    background-color: rgba(0, 0, 0, 0.352);
    z-index: 999999999;
    position: fixed;
    top:0px;
    left:0;
}
.edit-modal{
    font-family: "Quicksand", sans-serif;
    z-index: 999999;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #FFFCF8;
    width: 50%;
    max-width: 813px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.edit-top{
    width:100%;
    padding: 0 70px;
    display: flex;
    flex-direction: column;
}
.input-field{
    width: 100%;
    border: 1px solid #394B4266;
    height: 55px;
    border-radius: 32px;
    background: #FFFCF8;
    outline: none;
    font-size: 1.28em;
    font-weight: 500;
    padding: 0 20px;
}
.input-field::placeholder{
    font-size: 18px !important;
    font-weight: 400 !important;
    font-family: "Quicksand", sans-serif;
}
.input-div.mobile{
    padding: 0;
    width: 55%;
    margin-right: 20px;
}
.input-field.phone{
    padding: 0;
}
.input-field .form-control{
    width:100% !important;
    border:none !important;
    background: #FFFCF8 !important;
    height:100%;
    font-size: 1.25em !important;
    font-weight: 500 !important;
    border-radius: 32px !important;
    transition: none !important;
}
.save-edit{
    width:181px;
    height:55px;
    border:none;
    outline:none;
    border-radius: 32px;
    background: #49AF7C;
    font-size: 1.28em;
    font-weight: 500;
    color:white;
    align-self: flex-end;
    margin-top: 55px;
}
.editclose-icon{
    width:40px;
    height:40px;
    border-radius: 50%;
    background: #DCF6E9;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top:3%;
    right:3%;
    cursor: pointer;
}
.input-div{
    margin: 10px 0;
}
.input-div p,span{
    margin:0;
}
.input-head{
    font-size: 1.25em;
    font-weight: 400;
    margin-left: 18px !important;
}
.input-secrow{
    display: flex;
    width: 100%;
}
.edit-bottom{
    background: #E0F3E0;
    border-radius: 34px;
    padding: 30px 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.edit-bottom span{
    font-size: 1.4em;
    font-weight: 500;
    margin-bottom: 20px;
}
.bottom-input{
    display: flex;
    width:100%;
    justify-content: space-between;
}
.input-field.bottom{
    width:48%;
    background:#E0F3E0;
}
@media screen and (max-width:787px){
    .task-modal{
        width:100%;
        height:100%;
        border-radius: 0;
        overflow-y: scroll;
    }
    .edit-modal{
        width:90%;
        max-width: unset;
    }
    .edit-top{
        padding: 0 20px;
    }
    .save-edit{
        margin-top:70px !important;
    }
}
.calendar-booking {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  background-color: #fffcf8;
  align-items: center;
  justify-content: center;
}
.calendar-booking .backButton {
  position: absolute;
  top: 85px;
  left: 30px;
  height: 40px;
  width: 40px;
  background-color: #DCF6E9;
  border-radius: 50%;
  border: 0px;
  z-index: 100;
}

.calendar-booking h3 {
    font-size: 25px;
    text-align: center;
    font-weight: 600;
    font-family: "Quicksand";
    color: #394b42;
    padding-top: 20px;
  }

  .unlockBox {
    background-color: #fffcf8;
    box-shadow: 1px 1px 9px 1px #ececec;
    display: flex;
    flex-direction: row;
    width: 190px;
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 5px 10px 0px 5px;
    align-self: center;
    margin-top: 30px;
  }
  
  .unlockBox img {
    width: 40px;
    height: 40px;
  }
  
  .unlockBox p {
    font-size: 1.1em;
    font-weight: 500;
    font-family: "Quicksand";
    color: #394b42;
    padding-left: 8px;
  }

.leftBookingBox {
  width: 400px;
  margin-right: 30px;
}

.right-box-bookingImage {
  width: 800px;
  max-width: 100%;
  max-height: 90%;
  z-index: 99;
}

.bookingcalendar-heading {
  font-weight: 500;
  text-align: center;
  font-size: 3em;
}

.circle-icon-cpp {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #7e8ed9;
  margin-right: 10px;
  border-radius: 50%;
}

.circle-icon-cpp .MuiSvgIcon-root {
  color: white;
  font-size: 13px;
}

.right-ccbooking {
  flex-grow: 1;
  width: 60%;
  display: flex;
  flex-direction: row;
  align-self: center;
}

.calendar-rccbooking {
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #c4eed9;
  border-radius: 25px;
  padding: 20px 15px;
}

.timeslots-rccbooking {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 16px;
  padding-bottom: 0px;
  border: 0px;
  border-radius: 25px;
  background-color: #fffcf8;
  box-shadow: 1px 1px 9px 1px #ececec;
  min-width: 100px;
  max-width: 98%;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 20px;
  margin-left: 5px;
}

.timeslots-rccbooking p{
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    font-family: "Quicksand";
    color: #394b42;
}

.choose-session-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
}

.time-slot-category {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.time-period-title {
  margin-bottom: 8px;
  font-size: 1.1em;
  font-weight: 600;
  font-family: "Quicksand";
  color: #394b42;
}

.slots-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  max-width: 100%;
}

.slots-div button {
  border-radius: 12px;
  background-color: transparent;
  border: 2px solid #4CAF50;
  color: #4CAF50;
  padding: 8px 12px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.slots-div button:hover {
  background-color: rgba(76, 175, 80, 0.2);
}

.slots-div button.active {
  background-color: #4CAF50;
  color: white;
  border: 2px solid #388E3C;
}

.slots-div > * {
  flex: 1 1 calc(33.33% - 10px);
  min-width: 70px;
  max-width: 100px;
  text-align: center;
  padding: 8px;
  background: #f0f0f0;
  border-radius: 5px;
  cursor: pointer;
}

.slots-div > *:hover {
  background: #ddd;
}

.react-calendar__tile {
  border-radius: 12px;
  background-color: transparent !important;
  border: 2px solid transparent;
  transition: all 0.3s ease-in-out;
  text-align: center;
  padding: 10px;
  cursor: pointer;
}

.available-slot {
  border: 2px solid #49AF7C !important;
  color: #4CAF50 !important;
}

.react-calendar__tile:hover {
  background-color: rgba(76, 175, 80, 0.2) !important;
  border-color: #4CAF50 !important;
}

.react-calendar__tile--active {
  background-color: #4CAF50 !important;
  color: white !important;
}

.highlight-available {
  border: 2px solid yellow !important;
  border-radius: 8px;
}

/* Handle */

.timeslots-rccbooking::-webkit-scrollbar-thumb {
  background: #f9ce55;
  border-radius: 10px;
}

.timeslots-rccbooking button {
  display: block;
  width: 100%;
  height: 45px;
  border: 1px;
  box-shadow: 1px 1px 9px 1px #ececec;
  outline: none;
  background-color: #fffcf8;
  margin-bottom: 10px;
  border-radius: 25px;
  font-size: 1.1em;
  font-weight: 500;
  font-family: "Quicksand";
  color: #394b42;
}

.timeslots-rccbooking button.active {
  background-color: #49AF7C;
  box-shadow: 2px 1px 15px 3px #c3e8d5;
  border: none;
  color: white;
}

.bottomcompletecalendaron {
  width: 100%;
  bottom: 0px;
  padding-bottom: 20px;
  background-color: white;
  display: flex;
  justify-content: center;
}

.bottombarcalendaarbooking {
  width: 96%;
  height: 70px;
  background-color: white;
  box-shadow: 2px 1px 12px 3px #ececec;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  padding: 0px 30px;
  display: flex;
  justify-content: right;
  align-items: center;
  font-family: Brandon;
}

.bottombarcalendaarbooking button:first-child {
  border-radius: 40px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3a91c1;
  width: 115px;
  height: 40px;
  border: none;
  outline: none;
  color: white;
  font-size: 1.3em;
  font-weight: 500;
}

.bottombarcalendaarbooking button:last-child {
  border-radius: 18px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(225.34deg, #ff7f7f 0%, #ff7fb7 98.93%);
  width: 150px;
  height: 40px;
  border: none;
  outline: none;
  color: white;
  font-size: 1.3em;
  font-weight: 500;
}

.calendar-heading-title {
  font-size: 1.8em;
  font-weight: 700;
  letter-spacing: 0px;
  width: 380px;
  text-align: center;
  margin-bottom: 5px;
}

.calendar-heading-subtitle {
  font-size: 1em;
  font-weight: 500;
  font-family: "Quicksand";
  width: 380px;
  text-align: center;
  color: #394b42;
}

.timeslot-mobile {
  margin-top: 2rem;
}

.timeslot-mobile button {
  display: block;
  padding: 0.75rem;
  border: 1px solid #5787a4;
  outline: none;
  background-color: white;
  margin-bottom: 10px;
  border-radius: 8px;
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: 600;
  margin-left: 1rem;
}

.timeslot-mobile button.active {
  background-color: #895fe0;
  box-shadow: 2px 1px 15px 3px #7b7ecc;
  border: none;
  color: white;
}

.slots-div {
  width: 80vw;
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  scrollbar-width: 2px;
}

.slots-div::-webkit-scrollbar {
  width: 2px;
}

.slots-div::-webkit-scrollbar-track {
  background-color: #ececfb;
}

.slots-div::-webkit-scrollbar-thumb {
  background: #f9ce55;
  border-radius: 10px;
}

.bottombarcalendaarbooking button:last-child .MuiSvgIcon-root {
  margin-right: 8px;
}

.react-calendar {
  background-color: #c4eed9 !important;
}

.react-calendar .react-calendar__month-view__days button {
  border-radius: 10% !important;
  color: black !important;
}

.calendar-legend {
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  margin-top: 10px;
}

.legend-item {
  display: flex;
  align-items: center;
  font-size: 1em;
  font-weight: 500;
  font-family: "Quicksand";
  color: #394b42;
}

.selected-box,
.available-box {
  width: 14px;
  height: 14px;
  border-radius: 3px;
  margin-right: 5px;
}

.selected-box {
  background-color: #49AF7C;
}

.available-box {
  background-color: transparent;
  border: 2px solid #49AF7C;
}

.react-calendar .react-calendar__month-view__days button:nth-child(6n + 6) {
  margin-right: 0px !important;
}

.react-calendar__tile--now {
  background: transparent !important;
  color: #49af7c;
}

.react-calendar {
  border: 0px !important;
}

.react-calendar abbr[data-bs-original-title],
abbr[title] {
  text-decoration: none;
}

.react-calendar .react-calendar__year-view button {
  width: 100px !important;
  height: auto !important;
  border-radius: 0px !important;
}

.react-calendar .react-calendar__year-view .react-calendar__year-view__months {
  justify-content: center !important;
}

.react-calendar__tile--now {
  background: transparent !important;
  color: #49af7c;
}

.react-calendar .react-calendar__viewContainer .react-calendar__tile--active {
  background-color: #49af7c !important;
  color: #394b42 !important;
}

.react-calendar__month-view__days {
  gap: 10px;
  justify-content: space-between;
}


.react-calendar .react-calendar__tile {
  max-width: 40px !important;
  height: 40px !important;
}

.react-calendar .react-calendar__tile:disabled {
  background-color: transparent !important;
  color: rgba(57, 75, 66, 0.3) !important;
}

.react-calendar__month-view__days__day--weekend {
  color: #49af7c;
}

.react-calendar .react-calendar__tile--active:enabled:hover,
.react-calendar .react-calendar__tile--active:enabled:focus {
  background: #49af7c !important;
}

.btn-loginmain {
    width: 45%;
    margin-top: 20px;
    margin-left: 60px;
    margin-bottom: 40px;
}

.btn-loginmain button {
    width: 100%;
    height: 45px;
    border-radius: 50px;
    background-color:#49AF7C;
    border: none;
    outline: none;
    color: white;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 0.5px;
    font-family: "Quicksand";
}

.btn-loginmain1 {
  width: 45%;
  margin-top: 20px;
  padding-bottom: 40px;
  margin-left: 340px;
}

.btn-loginmain1 button {
  width: 100%;
  height: 45px;
  border-radius: 50px;
  background-color:#49AF7C;
  border: none;
  outline: none;
  color: white;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.5px;
  font-family: "Quicksand";
}

.ba-timeslotdisabled {
  cursor: not-allowed !important;
  background: rgba(73, 175, 124, 0.4) !important;
  color: #FFFCF880 !important;
}

@media screen and (min-width: 1050px) {
  .react-calendar {
    width: 100% !important;
    font-size: 12px !important;
  }

  .react-calendar .react-calendar__tile {
    padding: 5px !important;
    font-size: 10px !important;
  }

  .react-calendar .react-calendar__month-view__days button {
    font-size: 10px !important;
    padding: 2px !important;
  }
  .react-calendar .react-calendar__month-view__days button {
  }

  .container-cbooking {
    padding: 0px 30px;
  }

  .right-ccbooking {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .calendar-rccbooking {
    width: 100%;
    align-items: center;
  }

.ba-progress-container {
  position: relative;
  width: 110%;
  max-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  height: 2vh;
  margin-left: -30px;
}
  .timeslots-rccbooking {
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 95%;
  }

  .choose-session-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .slots-div {
    justify-content: center;
    flex-wrap: wrap;
  }
}
.ba-mobileScreen-container {
  width: 100%;
  background-color: #fffcf8;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ba-mobileScreen-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
}

.ba-mobileScreen-container h3 {
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  font-family: "Quicksand";
  color: #394b42;
  padding-top: 70px;
}

.ba-mobileScreen-progress-container {
  position: relative;
  width: 100%;
  max-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  height: 2vh;
}

.ba-mobileScreen-progress-step {
  width: 20px;
  height: 20px;
  background-color: white;
  border: 2px solid #ccc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  z-index: 2;
  margin-top: 2px;
}

.ba-mobileScreen-btn-Continuemain {
  width: 90%;
  margin-top: 25px;
  margin-bottom: 25px;
}

.ba-mobileScreen-btn-Continuemain button {
  width: 100%;
  height: 45px;
  border-radius: 50px;
  background-color: #49af7c;
  border: none;
  outline: none;
  color: white;
  font-weight: 500;
  font-size: 15px;
  font-family: "Quicksand";
}

.ba-mobileScreen-backButton {
  position: relative;
  margin-top: 75px;
  height: 18px;
  width: 18px;
  background-color: #dcf6e9;
  border-radius: 50%;
  border: 0px;
}

.ba-mobileScreen-unlockBox {
  background-color: #fffcf8;
  box-shadow: 1px 1px 9px 1px #ececec;
  display: flex;
  flex-direction: row;
  width: 90%;
  margin-bottom: 10px;
  border-radius: 10px;
  padding: 5px 10px 0px 5px;
  align-self: center;
  margin-top: 10px;
}

.ba-mobileScreen-unlockBox img {
  width: 40px;
  height: 40px;
}

.ba-mobileScreen-unlockBox p {
  font-size: 1.1em;
  font-weight: 500;
  font-family: "Quicksand";
  color: #394b42;
  padding-left: 8px;
}

.ba-mobileScreen-calendar-rccbooking {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #c4eed9;
  border-radius: 25px;
  padding-top: 15px;
  padding-bottom: 20px;
}

.ba-mobileScreen-calendar-heading-subtitle {
  font-size: 12px;
  font-weight: 500;
  font-family: "Quicksand";
  width: 90%;
  text-align: center;
  color: #394b42;
}

.ba-mobileScreen-timeslots-rccbooking {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 0px;
  border: 0px;
  border-radius: 25px;
  background-color: #fffcf8;
  box-shadow: 1px 1px 9px 1px #ececec;
  min-width: 90px;
  max-width: 98%;
  width: 90%;
  margin-top: 15px;
  margin-bottom: 20px;
  margin-left: 5px;
}

.ba-mobileScreen-timeslots-rccbooking p{
    font-size: 18px;
    text-align: center;
    font-weight: 600;
    font-family: "Quicksand";
    color: #394b42;
    margin-top: 15px;
}

.ba-mobileScreen-timeslots-rccbooking::-webkit-scrollbar-thumb {
  background: #f9ce55;
  border-radius: 10px;
}

.ba-mobileScreen-timeslots-rccbooking button {
  display: block;
  width: 100%;
  height: 45px;
  border: 1px;
  box-shadow: 1px 1px 9px 1px #ececec;
  outline: none;
  background-color: #fffcf8;
  margin-bottom: 10px;
  border-radius: 25px;
  font-size: 1.1em;
  font-weight: 500;
  font-family: "Quicksand";
  color: #394b42;
}

.ba-mobileScreen-timeslots-rccbooking button.active {
  background-color: #49AF7C;
  box-shadow: 2px 1px 15px 3px #c3e8d5;
  border: none;
  color: #fffcf8;
}

.ba-mobileScreen-choose-session-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 20px;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  margin-top: -30px;
}
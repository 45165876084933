.pnf-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  height: auto;
  background: #e9fdff;
}

.sp-image {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

.sp-text {
  width: 80%;
  text-align: center;
  margin-left: 10%;
  margin-right: 10%;
  font-size: 1.6em;
  font-weight: 400;
  font-family: Brandon;

}

.whatsapp-support-button,.email-support-button {
  background: linear-gradient(225.34deg, #c5cfff 0%, #96a8ff 98.93%);
  border-radius: 280.976px;
  color: white;
  font-size: 1em;
  font-weight: 400;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0.5rem 1rem;
  margin-bottom: 0.2rem;
}

@media screen and (max-width: 767px) {
  .sp-image {
    width: 100%;
    margin: auto;
  }

  .sp-text {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.5;
  }

  .whatsapp-support-button,
  .email-support-button {
    padding: 0.2rem 0.8rem;
  }
}
.fp-container-bliss {
  visibility: visible;
  opacity: 1;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #f8f8f87d;
  z-index: 1000;
  backdrop-filter: blur(3px);
  transition: all 0.25s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fp-container-bliss-hidden {
  visibility: hidden;
  opacity: 0;
}

.fp-loader-bliss {
  width: 350px;
  height: 350px;
}

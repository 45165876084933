.analytics-page{
    padding:0 30px;
    width:100%;
    font-family: "Quicksand", sans-serif;
    display: flex;
    flex-direction: column;
    gap: 25px;
}
.analytics-div{
    font-family: "Quicksand", sans-serif;
    width:100%;
    box-shadow: 0px 0px 10px 0px #394B421A;
    background: #FFFCF8;
    border-radius: 32px;
    max-width: 1198px;
    padding: 15px 25px;
    padding-right: 50px;
}
.analytics-div.top{
}
.analytics-div.bottom{
    display: flex;
    flex-direction: column;
}
.analytics-img{
    max-width: 100%;
}
.coins-streak{
    display: flex;
    align-items: center;
    border-radius: 32px;
    padding:5px 15px;
    margin-top: 30px;
    box-shadow: 0px 0px 10px 0px rgba(57, 75, 66, 0.2);
}
.coins-streak .right{
    width:80%;
}
.coins-streak .right .value{
    font-size: 1.25em;
    font-weight: 600;
}
.coins-streak .right .head{
    font-size: 16px;
    font-weight: 500;
}
.goals-right img {
    max-height: 30px;
    object-fit: contain;
}
.analytics-filter{
    background: #DCF6E9;
    border-radius: 32px;
    padding: 0 10px;
    height:40px;
    width:auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.analytics-filter select{
    border:none;
    outline: none;
    background: #DCF6E9;
    font-size: 1em;
    font-weight: 500;
    cursor: pointer;
}
.analytics-filter select option {
    cursor: pointer;
}
.awellness-top{
    width:100%;
    margin-bottom: 40px;
}
.awellness-top p{
    margin:0;
}
.awellness-head {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}
.awellness-head-assessment {
    justify-content: space-between;
}
.wellness-head{
    font-size: 22px;
    font-weight: 600;
}
.wellness-head .score{
    font-size: 1.5em !important;
    margin-left: 10px;
}
.analytics-heading {
    font-size: 22px;
    font-weight: 600;
}
.goals-description{
    display:flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    object-fit: contain;
}
.goals-description p,span{
    margin: 0;
}
.goals-left {
    flex: 2;
}
.goals-middle{
    flex: 2;
    min-width: 180px;
    display: flex;
    flex-direction: column;
    gap: 20px;   
}
.goals-middle .goals {
    display: flex;
    margin-bottom: 10px;
    gap: 10px;
    align-items: center;
}
.goals-middle .goals span {
    font-size: 16px;
    font-weight: 600;
}
.goal-div{
    display: flex;
    align-items: center;
}
.icon-div{
    border: 1px solid #394B42;
    width:45px;
    height:45px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    background-color: transparent;
}
.goals-desc .value{
    font-size: 1.25em;
    font-weight: 600;
}
.goals-desc .head{
    font-size: 14px;
    font-weight: 500;
}
.measure-div{
    display: flex;
    align-items: center;
    margin-bottom:40px;
}
.measure-div .icon{
    width:87px;
    height:87px;
    border-radius: 50%;
    background: #CBD1F0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}
.measure-div .icon.yoga{
    background: #FADD8A;
}
.goals-right{
    flex: 3;
}
.measure-right{
    width:80%;
}
.measure-right .head{
    font-size: 1.25em;
    font-weight: 500;
}
.mins-indicator{
    width:100%;
    background: #E0E0E0;
    border-radius: 32px;
    margin-top: 10px;
}
.mins-indicator .value{
    border-radius: 32px;
    border: 6px solid #7E8ED9;
}
.mins-div{
    font-size: 1.2em;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.analytics-filters{
    display: flex;
}
.analytics-filters .analytics-filter{
    margin-left: 10px;
}
.no-data{
    font-size: 1.25em;
    font-weight: 500;
    text-align: center;
    margin-top: 20px;
}
.assessment-content{
    width:auto;
    display: flex;
}
.assessment-head{
    /* font-size: 1.25em; */
    font-weight: 500;
    margin-right: 20px;
    text-align: right;
    padding-top: 15px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.assessment-data{
    display: flex;
    width: auto;
    overflow-x: scroll;
    padding-bottom: 20px;
}
.assessment-data::-webkit-scrollbar-thumb{
    background: #394B42;
}
.assessment-data::-webkit-scrollbar{
    height:8px;
}
.assessment-value{
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 500px;
    margin-right: 20px;
}
.assessment-value p{
    margin:0;
}
.avalue{
    width:61px;
    background: #96A8FF;
    border-radius: 4px;
}
.avalue.high{
    background: #7084E0;
}
.assessment-date{
    font-size: 0.75em;
    font-weight: 500;
}
.assessment-name{
    font-size: 1em;
    font-weight: 500;
}
.measure-btn{
    align-self: center;
    margin:auto;
    font-size: 16px;
    font-weight: 600;
    color:white;
    width:200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #49AF7C;
    border-radius: 32px;
    margin-top: 10px;
    padding: 8px 0;
    border: 0px;
}
.measure-btn span{
    margin-right: 20px;
}
.assessment-tags {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    font-size: 16px;
    margin: 10px;
}
.assessment-tag {
    display: flex;
    align-items: center;
    gap: 10px;
}
.assessment-tag > div {
    height: 20px;
    width: 20px;
    border-radius: 50%;
}

/* Assessment styles */
.assessments-page {
    padding:0 30px;
    width:100%;
    font-family: "Quicksand", sans-serif;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.assessments-heading {
    display: flex;
    align-items: center;
    gap: 25px;
    position: relative;
}
.assessments-heading button {
    width: 40px;
    height: 40px;
    border: 0px;
    border-radius: 50%;
    background-color: #DCF6E9;
}
.assessments-heading h1 {
    font-size: 22px;
    font-weight: 600;
    margin: 0;
}
.assessments-searchbar {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-radius: 100px;
    gap: 15px;
    max-width: 500px;
    background-color: #DCF6E9;
}
.assessments-searchbar input {
    outline: none;
    border: 0px;
    background: transparent;
    font-weight: 500;
    flex: 1;
}
.assessments-container {
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
.assessment-card {
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    width: 200px;
    box-shadow: 0px 0px 10px 0px rgba(57, 75, 66, 0.1);
    border-radius: 20px;
    line-height: normal;
}
.assessment-card-content h3 {
    font-size: 18px;
    font-weight: 600;
}
.assessment-card-btn {
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    padding: 15px;
    text-align: center;
    color: #fff;
    background-color: #49AF7C;
    border-radius: 100px;
}
.assessment-card-btn:hover {
    color: #fff;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    flex-direction: column;
}
.analytics-modal-content {
    position: relative;
    background-color: #DCF6E9;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 25px;
    padding-bottom: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    max-width: 90%;
    width: 400px;
    max-height: 80vh;
    overflow-y: auto;
}
.analytics-modal-content p{
    font-size: 1.2em;
    font-weight: 600;
    color:#394B42;
    font-family: 'Quicksand';
}
.image-row {
    display: flex;
    justify-content: center;
    gap: 15px;
}
.store-icon {
    width: 120px;
    height: auto;
}
.analytics-close-icon {
    position: absolute;
    width: 20px;
    height: 20px;
    cursor: pointer;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
}

@media screen and (max-width: 1050px){
    .analytics-page{
        padding: 0;
        padding-top: 70px;
        margin-bottom: 20px; 
    }
    .assessments-page {
        padding: 0px 15px;
        padding-top: 80px;
    }
    .analytics-img{
        width:150px;
        height:150px;
        object-fit: contain;
    }
    .analytics-div {
        padding: 15px 20px;
    }
    .analytics-div.top.mobile{
        display: block;
    }
    .goals-right{
        width: 100%;
    }
    .awellness-top{
        margin-bottom: 0px;
    }
    .goals-middle{
        min-width: 0px;
        margin-top: 10px;
    }
    .wellness-head{
        font-size: 1.4em;
    }
    .wellness-head .score{
        font-size: 1.5em !important;
        margin:0;
    }
    .analytics-heading {
        font-size: 16px;
    }
    .assessment-head{
        margin-right: 10px;
    }
    .analytics-filters {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: flex-end;
    }
    .atop-left {
        display: flex;
        gap: 5px;
    }
    .goals-description {
        flex-wrap: wrap;
    }
    .goals-right {
        min-width: 100%;
    }
    .assessment-tags {
        flex-wrap: wrap;
        gap: 10px;
        margin: 0px;
    }
    .assessment-tag {
        gap: 5px;
    }
    .assessments-heading h1 {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .assessment-card {
        flex: 1;
        max-width: 200px;
        min-width: 150px;
        width: auto;
    }
}
.complete-payment-plan {
  width: 100%;
  font-family: "Quicksand";
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fffcf8;
  min-height: 100vh;
}
.complete-payment-plan .backButton {
  position: absolute;
  top: 85px;
  left: 25px;
  height: 40px;
  width: 40px;
  background-color: #dcf6e9;
  border-radius: 50%;
  border: 0px;
  z-index: 100;
}

.book-now-btn {
  font-weight: 600;
  cursor: pointer;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  width: 200px;
  text-align: center;
  display: inline-block;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.book-now-container {
  position: absolute;
  right: 0px;
}

/* Default Green Background */
.book-now-btn.default {
  /* background-color: green; */
}

/* Red Background for PlanID 1001 */
.book-now-btn.special {
  /* background-color: red; */
}

/* Hover Effects */
.book-now-btn:hover {
  opacity: 0.8;
}

.vector5 {
  position: absolute;
  left: 20px;
  bottom: -110px;
  opacity: 0.2;
}

.vector6 {
  position: absolute;
  left: 0px;
  bottom: -75px;
  opacity: 0.2;
}

.container-rcpp h3 {
  font-size: 2.4em;
  font-weight: 600;
  font-family: "Quicksand";
  color: #394b42 !important;
  text-align: center;
}

.container-rcpp p {
  font-size: 1.15em;
  font-weight: 500 !important;
  margin-top: 30px;
}

.btns-crcpp {
  width: 100%;
  margin-top: 70px;
  text-align: center;
}

.btns-crcpp button {
  padding: 5px 10px;
  background-image: linear-gradient(to right, #fa93d2, #895fe0);
  width: 245px;
  height: 50px;
  box-shadow: 2px 1px 15px 3px #7b7ecc;
  border: none;
  outline: none;
  color: white;
  border-radius: 18px;
  font-size: 18px;
  letter-spacing: 1px;
  z-index: 99;
}

.container-rcpp ul {
  margin: 0px;
  margin-top: 30px;
  padding: 0px;
  list-style-type: none;
}

.container-rcpp ul.animated {
  animation: mymove 2s alternate;
}

@keyframes mymove {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.container-rcpp ul li {
  margin: 15px 0px;
  font-size: 1.2em;
  font-weight: 500 !important;
  display: flex;
}

.circle-icon-cpp {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #7e8ed9;
  margin-right: 10px;
  border-radius: 50%;
}

.circle-icon-cpp .MuiSvgIcon-root {
  color: white;
  font-size: 13px;
}

.left-cpp {
  max-width: 450px;
  flex-grow: 1;
  min-width: 500px;
  margin-top: 60px;
}

.container-lcpp {
  width: 100%;
  height: 80px;
  background-color: #fffcf8;
  box-shadow: 1px 1px 10px 1px #ececec;
  border: 0px;
  margin-bottom: 40px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 20px;
  position: relative;
}

.container-lcpp.active {
  height: 300px;
  background-color: #fffcf8;
  box-shadow: 1px 1px 10px 1px #ececec;
}

.subscription-title {
  width: 100%;
  margin-bottom: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 1.2em;
  font-weight: 600;
  font-family: "Quicksand";
  color: #394b42;
}

.subscription-subtitle {
  font-size: 0.95em;
  font-weight: 500;
  font-family: "Quicksand";
  color: #394b42;
}

.container-lcpp.active .subscription-title {
  color: black;
}

.subscription-title div {
  width: 25px;
  height: 25px;
  background-color: #ececec;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.container-lcpp.active .subscription-title div {
  background-color: #379e90;
  color: white;
}

.subscription-title .lesssubscription {
  display: none;
}

.subscription-title div .MuiSvgIcon-root {
  font-size: 18px;
}

.subscription-description {
  display: none;
  margin-top: 10px;
  color: black;
  font-size: 1.2em;
  font-weight: 500;
  margin-bottom: 50px;
  font-family: "Quicksand";
}

.subscription-price {
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 1.2em;
  display: none;
  font-family: "Quicksand";
  color: #394b42;
}

.container-lcpp.active .subscription-description {
  display: block;
}

.container-lcpp.active .subscription-price {
  display: block;
}

.container-lcpp.active .moresubscription {
  display: none;
}

.container-lcpp.active .lesssubscription {
  display: flex;
}

.price-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}
.price-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.price-row p {
  font-size: 1.6em;
  font-weight: 600;
  color: #49af7c;
  font-family: "Quicksand";
}

.price-details p:first-child {
  font-size: 1.3em;
  text-decoration: line-through;
}

.sessionName {
  font-size: 1em;
  font-weight: 500;
  color: #49af7c;
  font-family: "Quicksand";
  margin-top: -35px;
}

.left-cpp .container-rcpp {
  display: none;
}

.tag-specialsubscription {
  background-color: #7e8ed9;
  position: absolute;
  bottom: -22px;
  right: 22px;
  border-radius: 25px;
  padding: 10px;
}

.tag-specialsubscription p {
  margin: 0px;
  font-size: 1em;
  letter-spacing: 1px;
  text-align: center;
  font-weight: 600;
  font-family: "Quicksand";
  color: #fffcf8;
}

.popup-payment-alert {
  width: 400px;
  background-color: #c4eed9;
  border-radius: 15px;
  box-shadow: 2px 1px 15px 3px #ececec;
  height: 250px;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  transition: all 0.3s ease-in-out;
  z-index: 99999999;
}

.left-ppa {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.popup-payment-alert img {
  max-height: 100%;
}

.popup-payment-alert-overaly {
  width: 100%;
  background-color: rgb(247, 247, 247, 0.8);
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1000;
}

.msgCard {
  position: fixed;
  bottom: -100px;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 15px 20px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #333;
  min-width: 280px;
  z-index: 1000;
  width: 400px;
  height: 80px;
}

.msgCard p {
  margin: 0;
}

.progressBar {
  width: 100%;
  height: 5px;
  background: #e0e0e0;
  border-radius: 5px;
  margin-top: 10px;
  overflow: hidden;
}

.progressBar div {
  width: 100%;
  height: 100%;
  background: #4caf50;
  animation: progress 3s linear forwards;
}

@keyframes progress {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.close-ilppa {
  background-image: linear-gradient(131.58deg, #f3f4f6 1.34%, #c2d1ed 103.94%);
  width: 95px;
  height: 95px;
  border-radius: 50%;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-ilppa .MuiSvgIcon-root {
  font-size: 56px;
  color: red;
}

.desc-ilppa {
  font-size: 1em;
  font-weight: 500;
  font-family: "Quicksand";
  color: #394b42 !important;
  margin-left: 25px;
}

.title-ilppa {
  font-size: 2em;
  font-weight: 600;
  font-family: "Quicksand";
  color: #394b42 !important;
  text-align: center;
  margin-top: 10px;
}

.back-btn {
  border-radius: 40px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(225.34deg, #ff7f7f 0%, #ff7fb7 98.93%);
  height: 40px;
  border: none;
  outline: none;
  width: 110px;
  font-family: "Quicksand";
  font-weight: 400;
  font-size: 1.3em;
}

.book-txt {
  border: 0px;
  background-color: #49af7c;
  border-radius: 25px;
  padding: 12px;
  font-size: 0.9em;
  font-weight: 500;
  font-family: "Quicksand";
  color: #fffcf8;
}

.retrybtn {
  width: 230px;
  margin-top: 20px;
}

.retrybtn button {
  width: 100%;
  height: 45px;
  border-radius: 50px;
  background-color: #49af7c;
  border: none;
  outline: none;
  color: white;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.5px;
  font-family: "Quicksand";
}

.progress-container {
  position: relative;
  width: 100%; /* Ensure it takes full width */
  max-width: 100vw; /* Prevent accidental overflow */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}
.progress-line {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #ccc; /* Gray for incomplete progress */
  z-index: 1;
}
.progress-fill {
  position: absolute;
  top: 50%;
  left: 0;
  height: 4px;
  background-color: #49af7c; /* Green for completed progress */
  transition: width 0.3s ease-in-out;
  z-index: 2;
}
.progress-steps {
  display: flex;
  justify-content: space-between;
  width: 60%; /* Adjust width as needed */
  max-width: 800px; /* Prevent excessive stretching */
  position: relative;
}

.progress-step {
  width: 30px;
  height: 30px;
  background-color: white;
  border: 3px solid #ccc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  z-index: 2; /* Ensures it's above the progress line */
}

.completed {
  background: #49af7c;
  color: white;
  border-color: #49af7c;
}

.active {
  /* background: #D7D9D4; */
  color: white;
}

@keyframes expandLine {
  from {
    width: 0;
  }
  to {
    width: 100vw;
  }
}

@media screen and (max-width: 1050px) {
  .right-cpp {
    width: 100%;
    padding: 20px;
  }
  .right-cpp .container-rcpp ul,
  .right-cpp .vector5,
  .right-cpp .vector6,
  .right-cpp .btns-crcpp {
    display: none;
  }
  .tag-specialsubscription {
    left: -10px;
  }
  .left-cpp .container-rcpp {
    display: block;
  }
  .vector5 {
    position: absolute;
    left: 20px;
    bottom: 20px;
    opacity: 0.6;
    max-width: 200px;
  }
  .vector6 {
    position: absolute;
    left: 0px;
    bottom: -14px;
    opacity: 0.6;
    max-width: 200px;
  }
  .left-cpp {
    max-width: 100%;
    width: 100%;
    padding: 0px 20px;
    margin-top: 50px;
  }
  .complete-payment-plan {
    padding-top: 20px;
  }
  .subscription-title {
    font-size: 1.4em;
  }
}

.pp-mobileScreen-container {
  width: 100%;
  background-color: #fffcf8;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pp-mobileScreen-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
}

.pp-mobileScreen-container h3 {
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  font-family: "Quicksand";
  color: #394b42;
  padding-top: 70px;
  padding-left: 45px;
}

.pp-mobileScreen-progress-container {
  position: relative;
  width: 100%;
  max-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  height: 2vh;
}

.pp-mobileScreen-progress-step {
  width: 20px;
  height: 20px;
  background-color: white;
  border: 2px solid #ccc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  z-index: 2;
  margin-top: 2px;
}

.pp-mobileScreen-backButton {
  position: relative;
  margin-top: 75px;
  height: 18px;
  width: 18px;
  background-color: #dcf6e9;
  border-radius: 50%;
  border: 0px;
}

.pp-mobileScreen-container-lcpp {
    width: 80%;
    height: 80px;
    background-color: #fffcf8;
    box-shadow: 1px 1px 10px 1px #ececec;
    border: 0px;
    margin-bottom: 40px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 20px;
    position: relative;
  }

  .pp-mobileScreen-container-lcpp.active {
    height: 300px;
    background-color: #fffcf8;
    box-shadow: 1px 1px 10px 1px #ececec;
  }

  .pp-mobileScreen-container-lcpp.active .subscription-title {
    color: black;
  }
  
  .pp-mobileScreen-container-lcpp.active .subscription-title div {
    background-color: #379e90;
    color: white;
  }

  .pp-mobileScreen-container-lcpp.active .subscription-description {
    display: block;
  }
  
  .pp-mobileScreen-container-lcpp.active .subscription-price {
    display: block;
  }
  
  .pp-mobileScreen-container-lcpp.active .moresubscription {
    display: none;
  }
  
  .pp-mobileScreen-container-lcpp.active .lesssubscription {
    display: flex;
  }

  .pp-mobileScreen-tag-specialsubscription {
    background-color: #7e8ed9;
    position: absolute;
    bottom: -22px;
    right: 22px;
    border-radius: 25px;
    padding: 10px;
  }
  
  .pp-mobileScreen-tag-specialsubscription p {
    margin: 0px;
    font-size: 14px;
    text-align: center;
    font-weight: 600;
    font-family: "Quicksand";
    color: #fffcf8;
  }

  .pp-mobileScreen-left-cpp {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    min-width: 100%;
    margin-top: 10px;
    align-items: center;
    justify-content: center;
  }
.mood-card {
    height: 100%;
    background-color: rgba(251, 241, 216, 1);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 700px;
    z-index: 1;
    padding: 20px;
    position: relative;
    overflow: hidden;
    font-family: Brandon;
    margin: auto;
    width: 100%;
}

.mood-heading{
    font-size: 1.6em;
    font-weight: 500;
    margin: 0;
}

.semi-circle {
    width:80%;
    height: 100%;
    position: absolute;
    top:50%;
    border-radius: 50%;
    z-index: -1;
    align-self: center;
    border: 5px solid rgba(255, 244, 230, 1);
}

.mood-emojis{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height:100%;
}

.mood-emoji-circle{
    background-color: rgba(255, 252, 248, 1);
    width:54px;
    height:54px;
    border-radius:50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
}

.mood-emoji-circle:nth-child(1) {
    top: 72%;
    left: 5%;
}

.mood-emoji-circle:nth-child(2) {
    top: 48%;
    left: 20%;
}

.mood-emoji-circle:nth-child(3) {
    top: 38%;
    left: 43%;
}

.mood-emoji-circle:nth-child(4) {
    top: 48%;
    left: 65%;
}

.mood-emoji-circle:nth-child(5) {
    top: 72%;
    right: 5%;
}


.mood-emoji-circle span{
    font-size: 32px;
    transition: all 0.3s ease;
  }

.mood-emoji-circle.active{
    border: 1px solid rgba(67, 69, 86, 1)
}

.mood-emoji-circle span:hover{
    cursor: pointer;
}

.mood-arrow{
    position: absolute;
    display: none;
}
.mood-arrow.active{
    display: block;
}
.mood-arrow:nth-child(1){
    top:80%;
    left:25%;
}
.mood-arrow:nth-child(2){
    top:68%;
    left:32%;
}
.mood-arrow:nth-child(3){
    top:62%;
    left:46%;
}
.mood-arrow:nth-child(4){
    top:68%;
    left:58%;
}
.mood-arrow:nth-child(5){
    top:80%;
    right:25%;
}

.mood{
    font-size: 20px;
    font-weight: 500;
    margin: 0;
    text-align: center;
    width: 90%;
}

.mood-img-tcb img {
    max-height: 270px;
    border-radius: 14px;
    width: 250px;
}

@media screen and (max-width:767px){
    .mood-card{
        width: 90%;
        height: 240px;
        margin-bottom: -20px;
    }
    .moodcontent-heading{
        width: 100%;
        text-align: center;
    }
}
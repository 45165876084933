.mybookingsdashboard {
    width: 100%;
    height: 75%;
}

.left-mbd {
    width: 310px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.right-mbd {
    flex-grow: 1;
    height: 80%;
    display: flex;
    align-items: flex-start;
}

.lifetimemoodcount {
    margin: 0px auto;
    padding: 20px;
    border-radius: 8px;
    font-family: Brandon;
}

.title-ltmc {
    font-size: 1.6em;
    font-weight: 500;
}

.inner-ltmc {
    width: 320px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.container-ltmc {
    width: 48%;
    background-color: #FFDB97;
    margin-bottom: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top:10px;
}

.title-cltmc {
    font-size: 15px;
    font-weight: 500;
}

.container-ltmc .MuiSvgIcon-root {
    font-size: 35px;
}

.times-cltmc {
    font-weight: 500;
}

.mood-container {
    width: 100%;
    height: 250px;
}
.top-lmbd {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.top-lmbd img {
    width: 300px;
    height: 335px;
    border-radius: 8px;
}

.top-lmbd p {
    color: white;
    position: absolute;
    bottom: 0px;
}

.bottom-lmbd {
    flex-grow: 1;
    max-height: 420px;
    width: 100%;
    background-color: #FAFAFA;
    padding-top: 20px;
    margin-top: 70px;
    font-family: Brandon;
}

.top-blmd {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px auto;
    font-size: 1.2em;
    font-weight: 500;
    border-bottom: 1px solid gray;
    padding-bottom: 5px;
}

.top-blmd label:last-child {
    color: #7fcbee;
}

.rightleft-date {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin: 0px auto;
    margin-top: 5px;
    margin-bottom: 20px;
    position: relative;
}

.rightleft-date input {
    border: none;
    box-sizing: border-box;
    outline: 0;
    position: absolute;
    width: 80%;
    height: 100%;
    top: 0px;
    right: 30px;
    left: 30px;
    bottom: 0px;
    opacity: 0;
}

.rightleft-date input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

.icons-rld {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #E8E8E8;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rightleft-date p {
    font-size: 18px;
    letter-spacing: 1px;
    margin-bottom: 0px;
    font-weight: 600;
}

.days-bar {
    width: 90%;
    background-color: white;
    height: 180px;
    margin: 0px auto;
    border-radius: 8px;
    margin-top: 15px;
	margin-bottom: 15px;
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: center;
    position: relative;
    padding-top: 10px;
}

.block-db {
    width: 14%;
    height: 100%;
    text-align: center;
}

.block-db p {
    font-size: 13px;
}

.bar-bdb {
    width: 90%;
    margin: 0px auto;
    height: 50%;
    background-color: #FAFAFA;
}

.block-db.active .bar-bdb {
    background-color: #FFDB97;
}

.bottom-text-barsdb {
    text-align: right;
    width: 80%;
    margin: 0px auto;
    margin-top: 10px;
    font-weight: 600;
    position: absolute;
    right: 10px;
    bottom: 10px;
}

.bottom-lmbddone {
    border-top: 1px solid gray;
    padding-top: 10px;
    width: 80%;
    margin: 0px auto;
    margin-top: 15px;
    font-weight: 600;
}

@media screen and (max-width: 1050px) {
    .mybookingsdashboard {
        flex-direction: column !important;
    }
    .left-mbd {
        width: 100% !important;
    }
    .right-mbd {
        width: 100% !important;
    }
    .inner-ltmc {
        width: 100% !important;
    }
    .top-lmbd img {
        width: 320px !important;
    }
    .btns-workinline p {
        font-size: 9px !important;
    }
    .btns-workinline {
        padding-right: 0px !important;
    }
    .left-cbc {
        width: 300px !important;
    }
    .mid-cbc {
        width: 260px !important;
    }
}

@media screen and (max-width: 1500px) {
    /* .left-mbd {
        width: 200px;
    } */
    /* .top-lmbd img {
        width: 200px;
    } */
}

.overlay-mood-changer {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    z-index: 9999999999999;
}

.content-mood-changer {
    /* padding: 0px 120px; */
    /* height: 700px; */
    z-index: 99999999999999;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: white;
    padding: 12px;
    border-radius: 35px;
}

.content-mood-changer .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon .ant-steps-icon {
    display: none;
}

.content-mood-changer .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
    display: none;
}

.content-mood-changer .ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon {
    display: none;
}

.content-mood-changer img {
    border-radius: 32px;
    overflow: hidden;
    height: 540px;
	width:275px;
}

.content-mood-changer .ant-steps {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    max-width: 260px;
    top: 82%;
    align-items: center;
    background-color: white;
    border-radius: 50px;
    padding-left: 10px;
    padding-right: 10px;
    max-height: 15px;
}

.content-mood-changer .ant-steps-item-finish .ant-steps-item-icon {
    width: 7px;
    height: 7px;
    background-color: #7fcbee;
    border: none;
}

.content-mood-changer .ant-steps-item-wait .ant-steps-item-icon {
    width: 7px;
    height: 7px;
    background-color: #7fcbee;
    border: none;
}

.content-mood-changer .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
    background-color: #B7D3FF;
    border: 10px solid #7DB0FF;
}

.cmc-content {
    position: absolute;
    top: 15%;
}

.cmc-content p {
    text-align: center;
    color: white;
    font-size: 19px;
    letter-spacing: 1px;
    font-weight: 600;
}

.cmc-content p:nth-child(2) {
    margin-bottom: 0px;
}

.mobiletopbarcmc {
    position: absolute;
    top: 10px;
    background-color: white;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
    overflow: hidden;
}

.mobiletopbarcmc img {
    max-width: 200px;
	height:28px;
}

img {
    object-fit: cover;
    object-position: center;
}

@media screen and (max-width: 767px) {
    .content-mood-changer img {
        max-height: 520px !important;
    }
    .cmc-content p {
        font-size: 13px;
    }
    .content-mood-changer .ant-steps {
        max-width: 200px;
    }
    .mybookingsdashboard .top-lmbd img {
        width: 270px !important;
    }
    .content-mood-changer .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
        border: 7px solid #7DB0FF;
        width: 20px;
        height: 20px;
    }
}

.content-mood-changer:hover .btns-cmc {
    opacity: 1;
    pointer-events: all;
}

.btns-cmc {
    position: absolute;
    bottom: 35px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    pointer-events: none;
    transition: all .3s ease-in-out;
}

.btns-cmc button {
    margin: 0px 5px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 50%;
    width: 30px;
    height: 30px;
}

.btns-cmc button:first-child {
    background-color: red;
}

.btns-cmc .MuiSvgIcon-root {
    color: white;
    font-size: 18px;
}

.btns-cmc button:last-child {
    background-color: green;
}
.pieChart-Div {
  border-radius: 0.9375rem;
  background: #f6f8ff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 2rem;
}

.pie-div {
  font-weight: 500;
  color: #0d0d0d;
  width: 350px;
  height: 300px;
  text-align: center;
}

.notFound-text {
  padding-top: 5rem;
}

.monthlyEngagement-graph {
  border-radius: 0.9375rem;
  background: #f6f8ff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 1rem 1rem 1rem 0rem;
}

.senti-dis-div {
  border-radius: 0.9375rem;
  background: #f6f8ff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 2rem 1rem;
}

.happi-score-div {
  border-radius: 0.9375rem;
  background: #f6f8ff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 1rem 1rem 1rem 0rem;
}

.avg-benchmark-div {
  width: 50%;
  text-align: center;
  margin-top: 1.5rem;
}

.divider {
  width: 1px;
  border: 1px solid #afa1a1;
  height: 120px;
}

.last-month-score-div {
  width: 50%;
  text-align: center;
  margin-top: 1.5rem;
}

.right-details-div {
  height: fit-content;
  width: 56%;
  display: flex;
  flex-direction: row;
  flex-direction: row;
  margin: 0 3px;
  margin-top: 10rem;
  padding: 10px;
  border-radius: 0rem 1.875rem 1.875rem 0rem;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-width: 1px;
  border-color: #afa1a1;
  border-style: solid;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 0;
}

.up-down-div {
  border-radius: 0.3125rem;
  padding: 0.5rem 0.8rem;
  background: rgba(121, 71, 247, 0.2);
  display: flex;
  flexdirection: row;
}

.analytics-bottom{
  display:flex;
  align-items: center;
  margin-top: 40px;
}

.survey-div{
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 20px;
  border: 2px solid black;
  margin:auto;
}

.survey-top{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.survey-topright{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.survey-title{
  font-size: 1.5em;
  font-weight: 600;
}

.survey-select{
  text-decoration: none;
  background: #ffffff;
  border-radius: 20px;
  border: 1px solid #363A4E;
  padding: 0 10px;
  margin-bottom: 10px;
  width:150px
}

.survey-select-date{
  text-decoration: none;
  background: #ffffff;
  border-radius: 20px;
  border: 1px solid #363A4E;
  padding: 0 10px;
  margin-bottom: 10px;
  width:130px
}

.survey-select-item{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width:100%;
}
.survey-select-item p{
  margin: 0;
  margin-right: 10px;
}

.responses-div{
  background: #C4EED9;
  border-radius: 20px;
  border: 1px solid #363A4E;
  padding:10px;
  margin-bottom: 10px;
  font-weight: 500;
}
.responses-div p{
  margin: 0;
}
.zen-options{
  width:100%;
  display: flex;
  flex-direction: row;
  margin-top: 5vh; 
  justify-content:center;
}
.employees{
  margin-top: 2rem;
  width: 100%;
  height: 80%;
  padding-right: 10px;
  overflow-y: scroll;
  text-align: justify;
}
.employees::-webkit-scrollbar-thumb{
  background-color: rgb(150, 168, 255);
  height: "30%";
}
.pnf-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  height: auto;
  background: #e9fdff;
}

.pnf-image {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  /*left: 50%;
  top: 43%;
  margin-top: -149px;
  margin-left: -225px;
  position: absolute;
  z-index: 1000;*/
}

.addMargin120 {
  margin-top: 120px;
}

.addMargin50 {
  margin-top: 50px;
}

.pnf-heading {
  font-size: 3.5em;
  font-weight: 600;
  margin-top: 10%;
  margin-left: 5%;
  margin-right: 5%;
  background: -webkit-linear-gradient(#38bfe3, #44a2f9, #766ad3);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

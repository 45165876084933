.wellness-page{
    padding:0 20px;
    width:100%;
}
.wellness-top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "Quicksand", sans-serif;
}
.wsearch-div{
    width:50%;
    max-width: 743px;
    height:50px;
}
.wsearch button {
    border: 0px;
    outline: none;
    background-color: transparent;
}
.wsearch{
    border-radius: 32px;
    background: #DCF6E9;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width:100%;
    padding: 0 10px;
    height:50px;
    font-size: 1.1em;
    font-weight: 500;
}
.wsearch .search-input{
    outline: none;
    border:none;
    width:80%;
    background: #DCF6E9;
}
.wellness-topright{
    display: flex;
    align-items: center;
}
.wtop-btn{
    background: none;
    border-radius: 32px;
    border: 0px;
    box-shadow: 3px 3px 18px 3px #ececec;
    outline:none;
    height:50px;
    padding: 0px 20px;
    margin-right:20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.1em;
    font-weight: 500;
    background-color: #fffcf8;
}
.wtop-btn.active{
    color:white;
    border:none;
    background: #49AF7C;
}
.wtop-btn p{
    margin:0;
    margin-left:8px;
}
.wtop-btn.profile{
    border:none;
    width:auto;
    height:auto;
    padding: 0;
}
.wtop-btn.profile img{
    width:50px;
    height:50px;
}
.wellness-content{
    /* overflow-y: auto; */
    font-family: "Quicksand", sans-serif;
}
.wcontent-div{
    margin-bottom: 30px;
}
.wcontent-head{
    font-size: 1.8em;
    font-weight: 500;
}

.wcontent-links {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
}  

.library-div{
    display:flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-family: "Quicksand", sans-serif;
    margin: 20px 0;
}
.library-nav{
    display: flex;
    align-items:baseline;
    overflow-x: auto;
    scroll-behavior: smooth;
    width: 90%;
    padding: 10px;
    white-space: nowrap;
}
.library-nav::-webkit-scrollbar {
    display: none;
}

.lib-div p {
    white-space: pre-line;
}

.arrow-icon{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #DCF6E9;
    cursor: pointer;
    margin-bottom: 15px;
}
.arrow-icon.disabled{
    background: #DCF6E980;
}
.lib-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 30px;
    text-align: center;
    font-size: 1.1em;
    font-weight: 500;
}
.lib-icon{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #394B42;
    cursor: pointer;
    margin-bottom: 10px;
}
.lib-icon img{
    height: 45px;
}
.practice-div{
    width: 200px;
    height: 270px;
    font-family: "Quicksand", sans-serif;
    position: relative;
    cursor: pointer;
}
.lib-thumbnail{
    position: relative;
    border-radius: 32px 32px 0px 0px;
    height: 200px;
}
.lib-thumbnail img{
    width: 100%;
    height: 100%;
    border-radius: 32px 32px 0px 0px;
}
.bookmark-icon{
    position: absolute;
    top: 10px;
    right: 15px;
    z-index: 10;
}
.lib-infos{
    background: #fffcf8;
    width: 100%;
    height: 70px;
    border-radius: 0px 0px 32px 32px;
    box-shadow: 3px 3px 18px 3px #ececec;
    padding: 10px;
    position: absolute;
    bottom: 11.5px;
    left: 0;
    right: 0;
}
.lib-infos.history{
    /* bottom:-5px; */
}
.lib-info{
    display: flex;
    justify-content: space-between;
}
.lib-info p{
    margin:0;
    overflow: hidden;
}
.lib-name{
    color: #394B42;
    font-size: 1.1em;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.lib-content{
    color: #394B42;
    font-size: 1em;
    font-weight: 400;
    white-space: nowrap;
}
.watch-div{
    background: #FFFCF8;
    width:100%;
    height:8px;
    position: absolute;
    bottom:95px;
    left: 0;
    right: 0;
}
.watch-time{
    background: #49AF7C;
    width: 100%;
    height: 8px;
    border-radius: 0px 7px 7px 0px;
}
.lib-icon.selected{
    border: 4px solid #49AF7C;
    box-shadow: 1px 1px 8px 1px #394B4273;
    width:100px;
    height: 100px;
}

/* Modal Backdrop */
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

/* Modal Box */
.libraray-modal-content {
    position: absolute;
    top: 100%;
    left: 20px;
    width: 100%;
    background: white;
    background-color: #FFFCF8 !important;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    z-index: 10;
}

/* Filter Options */
.filter-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
}

.filter-options label {
    flex: 1 1 calc(50% - 10px);
    display: flex;
    align-items: center;
    gap: 5px;
}

@media screen and (max-width: 1050px){
    .wtop-btn{
        border: none;
        margin-left: 5px;
        margin-right: 0;
        border-radius: 50px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .wtop-btn p{
        display: none;
    }
    .wsearch-div{
        max-width: unset;
        width:75%;
    }
    .wellness-page{
        padding-top: 80px;
    }
    .lib-infos{
        height:auto;
        /* top:140px; */
        bottom:unset;
    }
    .wellness-top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: "Quicksand", sans-serif;
        padding-left: 10px;
        padding-right: 10px;
    }
    .wcontent-links{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }
    .practice-div{
        width:auto;
        max-width: 180px;
        min-width: 150px;
        flex: 1;
        height:200px;
        background-color: #fffcf8;
    }
    .lib-thumbnail{
        height:unset;
    }
}

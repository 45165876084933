.streak-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .streak-modal-content {
    background: #fffcf8;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    /* position: relative; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 430px;
  }
  
  .streakCount {
    font-size: 1.8em;
    font-weight: 600;
    font-family: "Quicksand";
    color: #394b42;
    margin-top: -40px;
    z-index: 99999;
  }
  
  .steakTxt {
    font-size: 20px;
    font-weight: 600;
    font-family: "Quicksand";
    color: #394b42;
    text-align: center;
  }
  
  .steakdec {
    font-size: 1em !important;
    font-weight: 500 !important;
    font-family: "Quicksand";
    color: #394b42;
    text-align: center;
    margin-top: 10px;
  }
  
  .DaysBox {
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #fffcf8;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    width: fit-content;
    margin-bottom: 10px;
  }
  
  .day-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50px;
  }
  
  .day-label {
    font-size: 12px !important;
    font-weight: 400 !important;
    font-family: "Quicksand";
    color: #394b42;
  }
  
  .active-day {
    font-size: 10px;
    font-weight: 400;
    font-family: "Quicksand";
    color: #49af7c !important;
    /* margin-bottom: -10px; */
  }
  
  .circle {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #ddd;
  }
  
  .lottie-icon {
    width: 35px !important;
    height: 35px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  @media (max-width: 480px) {
    .streak-modal-content {
      width: 90%;
      max-width: 350px;
      margin: 0 15px;
      padding: 15px;
    }
  
    .streakCount {
      font-size: 1.5em;
    }
  
    .steakTxt {
      font-size: 18px;
    }
  
    .day-item {
      width: 40px;
    }
  
    .circle {
      width: 15px;
      height: 15px;
    }
  
    .lottie-icon {
      width: 30px !important;
      height: 30px !important;
    }
  }  
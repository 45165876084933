.overview {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.topoverview-header{
    display: flex;
    width: 85%;
    margin: auto auto 20px auto;
    justify-content: space-between;
    align-items: center;
}
.top-overview {
    width: 85%;
    height: 600px;
    margin: 0px auto;
    display: flex;
}

.bottom-overview {
    width: 85%;
    height: 40%;
    padding-top: 50px;
    margin: 0px auto;
    display: flex;
    align-items: flex-end;
}

.title-topoverview {
    font-size: 18px;
}

.box-bubbles {
    flex-grow: 1;
    width: 100%;
    /* border: 1px solid red; */
    border-radius: 12px;
    position: relative;
    max-width: 800px !important;
    max-height: 500px !important;
}

.blue-bubble-box {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    background-image: linear-gradient(to top, rgba(255, 255, 255, 0.1), #bf8ff0);
    transform: translate(-50%, -50%);
    box-shadow: 3px 3px 18px 3px #e0c1ff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
}

.red-bubble-box {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    position: absolute;
    left: 73%;
    top: 50%;
    background-image: linear-gradient(to top, rgba(255, 255, 255, 0.1), #EB334A);
    transform: translate(-50%, -50%);
    box-shadow: 1px 1px 12px 3px #ffaeb8;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
}

.black-bbb {
    color: black;
}

.white-bbb {
    color: white;
    margin: 0px;
}

.left-bov {
    width: 60%;
    height: 90%;
    display: flex;
    flex-direction: column;
}

.right-bov {
    width: 60%;
    border: 5px solid #3EAFFC;
    /* height: 100%; */
    background-color: hsl(227, 100%, 98%);
    padding: 15px;
    margin-bottom: 8%;
}

.chart-lbov {
    width: 300px;
    /* border: 1px solid red; */
    flex-grow: 1;
    margin-top: -50px;
}

.overview .bottom-overview {
    padding: 0px;
    height: 500px;
}

.status-rbov {
    width: 100%;
    display: flex;
    align-items: center;
}

.count-rightbov {
    font-size: 1.5em;
    margin: 0px;
    letter-spacing: 2px;
}

.status-emoji {
    width: 40px;
    height: 40px;
    background-color: #d2d0db;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
}

.status-emoji .MuiSvgIcon-root {
    fill: #E0AF55;
}

.lineargraphstatus {
    width: 100%;
    height: 150px;
    /* background-color: black; */
}

.lineargraphstatus .recharts-default-tooltip {
    background-color: #7947F7 !important;
}

.lineargraphstatus .recharts-tooltip-label {
    color: white;
}

.overview .recharts-cartesian-axis-tick text {
    font-size: 12px;
    font-weight: 600;
}

.filters {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 20px;
}

.topoverview-header .filters{
    margin: auto;
}

.select-div {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
    box-shadow: 2px 1px 8px 3px #d4d4d4;
    border-radius: 12px;
    width: 85%;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

@media screen and (max-width: 1050px) {
    .top-overview {
        height: auto !important;
        flex-direction: column;
    }

    .box-bubbles {
        height: 800px !important;
        max-height: 800px !important;
    }

    .bottom-overview {
        flex-direction: column !important;
        width: 95% !important;
    }

    .left-bov {
        width: 100% !important;
    }

    .chart-lbov {
        width: 100%;
        height: 350px !important;
        margin-top: 0px !important;
    }

    .right-bov {
        width: 100% !important;
        margin: 0px auto !important;
        padding: 0px !important;
    }

    .lineargraphstatus {
        width: 90% !important;
        height: 200px !important;
        margin: 0px auto !important;
        margin-bottom: 30px !important;
    }

    .title-topoverview {
        width: 90% !important;
        margin: 0px auto !important;
        margin-top: 30px !important;
        font-size: 18px !important;
    }

    .title-topoverview.painpoint{
        margin-top: 0px !important;
    }

    .status-rbov {
        width: 90% !important;
        margin: 0px auto !important;
    }

    .chart-lbov .recharts-responsive-container tspan {
        font-size: 11px;
    }
}

.tooltipcontent {
    display: flex;
    align-items: center;
    background-color: #7947F7;
    padding: 7px 10px;
    color: white;
    font-size: 13px;
    border-radius: 4px;
}

.tooltipcontent .MuiSvgIcon-root {
    margin-left: 10px;
    font-size: 17px;
    fill: #E0AF55;
}

@media screen and (max-width: 767px) {
    .right-bov .title-topoverview {
        font-size: 18px !important;
    }

    .blue-bubble-box {
        width: 120px;
        height: 120px;
        top: 41%;
    }

    .overview .bottom-overview {
        height: auto !important;
    }
}
.scheduledashboard {
    font-family: "QuickSand";
} 
.scheduledashboard-empty {
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-family: "QuickSand";
}
.scheduledashboard-empty > div {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 25px;
}
.scheduledashboard-empty img, .empty-upcoming img {
    max-width: 130px;
}
.scheduledashboard-empty button, .empty-upcoming button {
    outline: none;
    border: 0px;
    padding: 18px 50px;
    font-weight: 600;
    color: #fff;
    background-image: url("assets/svg_icons/button-bg.svg");
    background-color: transparent;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.empty-upcoming {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-family: "QuickSand";
    height: 50vh;
    gap: 25px;
    text-align: center;
}

.scheduledashboard .containers-dashboardboxed {
    display: flex;
    height: 82%;
}

.date-clscd {
    width: 70px;
    height: 80px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 1px 8px 3px #d4d4d4;
    background-color: white;
    margin-right: 20px;
}

.date-clscd p {
    margin: 0px;
}

.content-clscd {
    flex-grow: 1;
}

.date-clscd p:first-child {
    font-size: 1.2em;
    font-weight: 500;
    margin-bottom: 5px;
}

.date-clscd p:last-child {
    font-size: 1.2em;
    font-weight: 500;
}

.title-cclscd {
    font-size: 1.3em;
    font-weight: 500;
}

.inline-clscd {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
}

.inline-clscd p {
    font-size: 13px;
    letter-spacing: 1px;
}

.btns-workinline {
    display: flex;
    align-items: center;
    padding-right: 25px;
    position: absolute;
    right: 0px;
    bottom: 15px;
}

.btns-workinline p {
    margin: 0px 10px;
    font-size: 1em;
    font-weight: 500;
    display: flex;
    letter-spacing: 0.5px;
    align-items: center;
    cursor: pointer;
}

.box-clscd {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.top-lscd {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.top-sblcb-completed{
	width: 100%;
    height: 60px;
    box-shadow: 2px 2px 6px 2px #ececec;
    display: flex;
    padding: 0px 10px;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
	background-image:linear-gradient(to bottom right,#FA93D2, #895FE0);
	color:#fff;
	font-size:13px;
}

.top-sblcb-completed p{
	padding-bottom:0px;
	margin-bottom:0px;
	width:95%;
}
.booking-action-btns {
    gap: 5px;
}
.completed-header {
    margin-top: 20px;
}
.sessions-month-select {
    justify-content: flex-end;
    max-width: 850px;
    padding: 10px 0;
}
.upcoming-sessions-row, .completed-sessions-row {
    margin: 15px 0 !important;
    width: 100% !important;
    box-shadow: none !important;
    border: 1px solid #878787;
}
.therapist-session-date {
    box-shadow: none !important;
    border-right: 1px solid #000;
    border-radius: 0;
}

@media screen and (max-width: 1280px) {
    .btns-rscd {
        width: 100%;
        justify-content: center;
    }
    .btns-rscd button:first-child {
        margin-left: 0px;
    }
}

.MuiDialog-paperWidthSm {
    max-width: 500px;
}

.MuiDialogContentText-root {
    font-weight: 500 !important;
    color: #8c8c8c !important;
}

.MuiButton-root {
    min-width: 120px !important;
}

.MuiDialogActions-root .MuiButton-root:first-child {
    border: 2px solid gray;
    color: black;
    text-transform: none !important;
    font-weight: 500 !important;
    letter-spacing: 1px;
}

.MuiDialogActions-root .MuiButton-root:nth-child(2) {
    color: white;
    background-color: #069da8;
    text-transform: none !important;
    letter-spacing: 1px;
}

.tooltipclass {
    border-radius: 20px;
}

.select-therapist-recommendations {
    position: absolute;
    top: 200px;
    left: 40px;
}

.title-str {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 25px;
}

.usession-div {
    background: #FFFCF8;
    box-shadow: 0px 0px 10px 0px #394B421A;
    border-radius: 28px;
    padding: 20px;
    margin: 10px 0 20px;
    color: #394B42;
    font-family: "Quicksand", sans-serif !important;
}
.usession-head{
    font-weight: 600 !important;
    font-size:2em !important;
}
.usession-div p {
    font-weight: 500;
    margin: 0;
}
.usession-top {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}
.usession-heading{
    display: flex;
    font-weight: 500;
    font-size: 1.2em;
    padding-left: 8px;
    padding-right: 13px;
}
.usession-heading p{
    text-align: center;
}
.usession-content {
    display: flex;
    gap: 15px;
}
.usession-content.limit-height {
    height: 70px;
    overflow: hidden;

}
.usession-content > div {
    flex: 1;
}
.usession-content p {
    font-weight: 600;
    margin-bottom: 5px;
}
.usession-content-tags {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
}
.usession-content-tags > div {
    outline: none;
    border: 0px;
    padding: 8px 14px;
    font-size: 12px;
    border-radius: 100px;
    background-color: #DCF6E9;
}
.usession-content > div:first-child {
    align-self: flex-start;
    display: flex;

}
.usession-content > div:first-child > div  {
    outline: none;
    border: 0px;
    padding: 8px 14px;
    font-size: 12px;
    border-radius: 100px;
    background-color: #DCF6E9;
}
.usession-content-bio {
    
}
.usession-btn-cont {
    display: flex;
    justify-content: center;
    align-items: center;
}
.usession-btn {
    outline: none;
    border-radius: 50px;
    padding: 2px 10px;
    background-color: transparent;
    border: 0px;
    box-shadow: 0px 0px 10px 0px rgba(57, 75, 66, 0.1);
    margin-bottom: 5px;
}
.completed-cancelled {
    font-weight: 500;
    padding: 8px 20px;
    text-align: center;
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: #FCF2D8;
    border-radius: 100px;
}
.completed-cancelled span {
    color: var(--text-primary);
}

.completed-active {
    background-color: #49AF7C;
    font-weight: 600;
    padding: 8px 15px;
    text-align: center;
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 100px;
    color: #ffffff;
}

.thcname-value{
    display: flex;
    align-items: center;
    width:30%;
}
.thcname-value img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}
.thcsession-value{
    width: 30%;
    font-size: 1.2em;
    font-weight: 500;
    text-align: center;
    margin-left: 20px;
}
.thctasks-value{
    width:40%;
    font-size: 1.2em;
    font-weight: 500;
    text-align: justify;
    margin-left: 20px;
}
.usession-body{
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.usession-body.com{
}
.usession-body::-webkit-scrollbar-thumb{
    background: black;
}
.thname-value {
    display: flex;
    align-items: center;
}
.thname-value img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    object-fit: contain;
    border-radius: 50%;
}
.name-div{
    width:100%;
}
.name-div .name {
    white-space: nowrap;
    font-size: 1.25em;
    font-weight: 600;
}
.name-div .head{
    color: #49AF7C;
    font-size: 1em;
    font-weight: 500;
}
.thsession-value{
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
}
.thsession-value img {
    max-width: 20px;
    object-fit: contain;
    margin-right: 5px;
}
.upsession-btn{
    border:none;
    outline:none;
    background: #49AF7C;
    color: white;
    text-decoration: none;
    border-radius: 32px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
}
.upsession-btn.disable{
    background: rgba(73, 175, 124, 0.4);
    color: #394B424A;
}
.upsession-btn.cancel{
    background: #DCF6E9;
    color:#394B42;
}
.upsession-btn p{
    margin-right: 10px !important;
}
.upsession-btn.join{
    margin-left: auto;
}
.ubtns-div{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap:20px;
}
.ubtns-div-mobile {
    display: none;
}
.completed {
    justify-content: center;
    display: flex;
    align-items: center;    
}

.uchat-div{
    background: #49AF7C;
    cursor: pointer;
    width:50px;
    height:50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: 10px;
}
.ujoin-div{
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 120px;
}
.usession-container {
    display: flex;
    align-items: center;
    width:100%;
    gap: 15px;
}
.usession-container > div {
    flex: 1;
}
.usession-container.last{
    border: none;
}
.usession-heading .th-name{
    width:30%;
}
.usession-heading .th-session{
    width:20%;
    text-align: center;
    margin-left: 12px;
}
.usession-heading .th-cname{
    width:30%;
}
.usession-heading .th-csession{
    width:30%;
    text-align: center;
    padding-left: 12px;
}
.usession-heading .th-ctasks{
    width:40%;
    padding-left: 12px;
}
.session-filters{
    display: flex;
    justify-content: flex-end;
}
.sessions-category {
    display: flex;
    align-items: center;
    gap: 20px;
}
.sessions-category button {
    outline: none;
    border: 0px;
    padding: 12px 20px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 100px;
    background-color: #DCF6E9;
    transition: all 0.2s;
}
.sessions-category button.active {
    background-color: #49AF7C;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
}
.session-filter{
    background: #DCF6E9;
    border-radius: 32px;
    padding: 10px 15px;
    margin-left:10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    cursor: pointer;
}
.session-filter select{
    border:none;
    outline: none;
    background: #DCF6E9;
    font-weight: 500;
    cursor: pointer;
}


.signout-modal{
    font-family: "Quicksand", sans-serif;
    z-index: 999999;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #FFFCF8;
    width: 360px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px 35px;
}
.signout-head{
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}
.sbtns{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    width:80%;
    align-self: center;
}
.signout-btn{
    background: #49AF7C;
    color:#FFFCF8;
    font-size: 15px;
    font-weight: 500;
    width:40%;
    height:40px;
    border-radius: 32px;
    border:none;
    outline:none;
}
.signout-btn.no{
    background: #C4EED9;
    color:#394b42;
    font-weight: 500;
}

@media screen and (max-width:1230px){
    .thsession-value,.thcsession-value{
        width: 100%;
        text-align: left;
        margin:10px 0px !important;
    }
    .mobile-head, .mobile-value{
        display: block;
    }
    .thcsession-value .mobile-value svg{
        margin-right: 10px;
    }
    .thsession-value .mobile-value svg{
        margin-right: 10px;
    }
    .thctasks-value{
        width: 100%;
        text-align: left;
        margin: 0;
    }
    .dashboardofboxes.scheduledashboard{
        margin-top: 0px;
    }
    .session-filters {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: flex-end;
    }
    .usession-div{
        height:auto;
        padding: 15px;
    }
    .thname-value,.thcname-value{
        width: 100%;
        justify-content: space-between;
    }
    .thname-value,.thcname-value img{
        margin:0;
    }
    .usession-body{
        height:auto;
    }
    .usession-head{
        font-size: 1.5em !important;
    }
    .updatetime{
        display: none;
    }
    .usession-container{
        flex-wrap:wrap;
    }
    .thname-value{
        width:100%;
    }
    .usession-heading{
        display: none;
    }
    .upsession-btn.join{
        width:100%;
    }
    .ubtns-div{
        width:100%;
        max-width: unset;
    }
    .ubtns-div.in-progress{
    }
    .upsession-btn.join{
        margin-left: 0;
    }
    .uchat-div.hide{
        display: none;
    }
}


@media screen and (max-width: 1050px) {
    .inline-clscd {
        width: 100%;
        text-align: center;
        padding: 0px;
    }
    .inline-clscd p {
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
    }
    .btns-workinline {
        position: static;
    }
    .date-clscd {
        margin-bottom: 20px;
        margin-top: 20px;
    }
    .select-therapist-recommendations {
        position: static;
        margin-top: 30px;
        width: 100%;
    }
    .title-str {
        font-size: 14px;
    }
    .title-cclscd {
        text-align: center;
    }

    .main-title-lscd {
        text-align: center;
        margin-top: 5px;
    }
    .scheduledashboard {
        min-height: 100vh;
        padding-top: 80px !important;
    }
    .sessions-category {
        gap: 10px;
    }
    .usession-top {
        margin-top: 10px;
        gap: 10px;
    }
    .session-filter:last-child {
        margin-left: 0px;
    }
    .ubtns-div {
        display: none;
    }
    .ubtns-div-mobile {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 15px;
    }
    .ubtns-div-mobile button {
        flex: 1;
    }
    .usession-container {
        flex-direction: column;
    }
    .thsession-value {
        justify-content: space-evenly;
    }
    .usession-content {
        flex-direction: column;
    }
    .usession-content.limit-height {
        height: 110px;
    }
}
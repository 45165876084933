.profile-cont {
  visibility: visible;
  opacity: 1;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #394b424d;
  z-index: 1000;
  padding: 15px;
  padding-top: 80px;
  overflow-y: scroll;
  /* backdrop-filter: blur(3px); */
  transition: all 0.25s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  /*background: #000000a4;*/
}

.profile-cont-hidden {
  visibility: hidden;
  opacity: 0;
}

.profile {
  background-color: #fffcf8;;
  width: 100%;
  max-width: 600px;
  border-radius: 34px;
  position: relative;
  padding: 20px 20px 10px;
}
.profile-div {
  max-width: 400px;
  padding: 40px 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.profile-div label {
  color: #394b42;
  margin-left: 16px;
  font-size: 0.857em;
}
.profile-div input,
.profile-div select {
  border-radius: 32px;
  padding: 8px 16px;
  /* border: 1px solid #394b42; */
  color: #000;
  background-color: #fffcf8;
  box-shadow: 1px 1px 10px 1px #ececec;
  border: 0;
  outline: none;
}
.profile-div input:disabled,
.profile-div select:disabled {
  opacity: 0.5;
  box-shadow: 1px 1px 5px 1px rgba(128, 128, 128, 0.5);
}
.profile-div-input-cont {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.profile-div-input-cont2 {
  display: flex;
  gap: 5%;
}
.profile-close {
  position: absolute;
  top: 15px;
  right: 15px;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dcf6e9;
  border: 0;
}
.profile-cta {
  align-self: flex-end;
  min-width: 120px;
  padding: 8px 16px;
  border-radius: 32px;
  border: 0;
  color: #fff;
}
.profile-cta-cont {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
}
.profile-mandatory-msg {
  color: red;
  text-align: center;
}
.profile-div-input-mobile {
  display: flex;
}
.profile-div-input-mobile-cta {
  border: 0;
  background-color: transparent;
  border-radius: 32px 0 0 32px;
  padding: 8px 16px;
  padding-right: 8px;
  border: 1px solid #394b42;
  color: #000;
  border-right-width: 0;
  background-color: #fffcf8;
  box-shadow: 2px 2px 6px 2px #ececec;
}
.profile-div-input-mobile-cta:disabled {
  opacity: 0.5;
}

.profile-div-input-mobile-cta-cont {
  position: relative;
}
.profile-div-input-mobile-cta-drpdwn {
  width: 100%;
  background-color: white;
  box-shadow: 2px 2px 6px 2px #ececec;
  position: absolute;
  top: 100%;
  border-radius: 8px;
  z-index: 999;
  max-height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  max-height: 250px;
  overflow-y: auto;
}
.profile-div-input-mobile-cta-drpdwn button {
  background-color: transparent;
  border: 0;
}

.profile-div-input-mobile-cta,
.profile-div-input-mobile input {
  background-color: #fffcf8;
  box-shadow: none;
  border: 1px solid #ccc;
  outline: none;
}

.profile-div-input-mobile input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-left: 8px;
  background-color: #fffcf8;
  box-shadow: 1px 1px 10px 1px #ececec;
  border: 0;
  outline: none;
}
.profile-div-input-mobile input::-webkit-outer-spin-button,
.profile-div-input-mobile input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.profile-div-input-age::-webkit-outer-spin-button,
.profile-div-input-age::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  background-color: #fffcf8;
  box-shadow: 1px 1px 10px 1px #ececec;
  border: 0;
  outline: none;
}
.avatar-list-cont {
  display: flex;
  gap: 8px;
  padding: 20px 30px;
  overflow-x: auto;
}

.avatar-scroll-btn-left {
  background: none;
  border: none;
  cursor: pointer;
}

.avatar-scroll-btn-right {
  background: none;
  border: none;
  cursor: pointer;
}

.avatar-list {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  gap: 15px;
  flex: 1;
}

.avatar-list-cont::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}

/* Track */
.avatar-list-cont::-webkit-scrollbar-track {
  background: #e4e4e4;
}

/* Handle */
.avatar-list-cont::-webkit-scrollbar-thumb {
  background: #b3b3b3;
}

/* Handle on hover */
.avatar-list-cont::-webkit-scrollbar-thumb:hover {
  background: #878787;
}

.avatar-item {
  width: 70px;
  height: 70px;
  object-fit: contain;
  border-radius: 50%;
  /* padding: 4px 10px; */
}

.avatar-item-center {
  height: 90px;
  width: 90px;
  padding: 5px;
  border-radius: 50%;
  border: 2px solid var(--theme-primary);
}

.avatar-scroll-btn-left,
.avatar-scroll-btn-right {
  transition: opacity 0.3s ease;
}

.avatar-scroll-btn-left.disabled,
.avatar-scroll-btn-right.disabled {
  opacity: 0.5; /* Adjust this to control the fade effect */
  cursor: not-allowed; /* Change the cursor to indicate that it's disabled */
}

.avatar-scroll-btn-left.disabled:hover,
.avatar-scroll-btn-right.disabled:hover {
  opacity: 0.5; /* Ensure opacity doesn't change on hover when disabled */
}

.input-field-ui {
  background-color: #fffcf8;
  box-shadow: 1px 1px 10px 1px #ececec;
  border: 0;
  outline: none;
  padding: 8px 16px;
  border-radius: 32px;
}

@media screen and (max-width:767px){
  .profile-div-input-mobile-cta {
    width: 50px;
    padding: 0px;
    height: 40px;
  }
}
.location-div {
  z-index: 999999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 400px;
  height: auto;
  border-radius: 20px;
  text-align: center;
  padding: 25px;
  font-family: Brandon;
}
.location-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
}

.location-select-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.location-div h3 {
    font-weight: 500;
    font-size: 20px;
}

.location-div select {
    width: 150px;
    border-radius: 6px;
    padding: 10px;
}

.location-div button {
    padding: 8px 20px;
    outline: none;
    border: 0px;
    border-radius: 6px;
    font-weight: 500;
    background: linear-gradient(225.34deg, #FF7F7F 0%, #FF7FB7 98.93%);
    color: #FFF;
}
.location-div button:disabled {
  opacity: 0.5;
}
.rating-div {
    z-index: 999999;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 550px;
    height:auto;
    border-radius: 20px;
    text-align: center;
    padding: 20px;
    font-family: Brandon;
  } 
.submit-btn {
    height: 48px;
    width: 121px;
    margin-top: 15px;
    background: linear-gradient(225.34deg, #FF7F7F 0%, #FF7FB7 98.93%);
    font-weight: 600;
    color: #fff;
    border: none;
    border-radius: 30px;
    font-size: 1.5em;
  }
  .rating-desc {
    height: 100px;
    width: 100%;
    padding: 10px 15px;
    border-radius: 10px;
    border: 1px solid #B7B7B7;
    resize: none;
    font-size: 1.2em;
    margin-top: 20px;
  }
  .options li {
    display: inline-block;
    padding: 8px 20px;
    border-radius: 30px;
    margin: 0 4px 10px;
    font-weight: 600;
    position: relative;
  }
  .options {
    padding: 0;
    list-style: none;
    margin-top: 20px;
    margin-bottom:0px;
  }
  .options li .checkcontainer {
    width:100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    margin: auto;
    right: 0;
  }
  .options li .checkcontainer input {
    position: absolute;
      opacity: 0;
      cursor: pointer;
      width: 100%;
      height: 100%;
  }
  .options li .radiobtn {
    height: 100%;
    width: 100%;
    display: inline-block;
    border-radius: 30px;
    border: 1.17px solid #B2B2B2
  }
  .options li .checkcontainer input:checked~.radiobtn::after {
    background: transparent;
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 30px;
    margin: auto;
    border: 2px solid #ffb73d;
  }
  
  .rating{
    width:200px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
  }

  .user-img{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin:20px 0;
  }
  
  .user-name {
    font-weight: 500;
  }
  
  .rating-div .user {
    height: 120px;
    width: 120px;
    background: rgb(250, 249, 249);
    border-radius: 50%;
    margin: 20px auto;
  }
  
  .popup-heading {
    font-size: 1.5em;
  }

  .rating-emoji{
    margin-left: 2px;
  }

  .option-div{
    display:flex;
  }
  .option-emoji{
    margin:0;
    margin-right:10px;
  }
  .option-title{
    margin:0;
  }
.rating-error{
  font-size: 1.2em;
  color:rgb(240, 38, 51);
  font-weight: 500;
}
@media screen and (max-width:767px){
  .rating-div {
    width: 100%;
    height:100%;
    transform:none;
    top:0;
    left:0;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    justify-content: center;
    background-image: url("https://ucarecdn.com/8f5b04df-e6ef-4276-b61b-deec97733d0c/ratingbg.png");
    background-size:contain;
  } 
  .rating-content{
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .rating-content::-webkit-scrollbar-thumb{
    display: none;
  }
}
.otp-screen-main-container {
  width: 100%;
  margin: 0px auto;
  min-height: 100vh;
  display: flex;
  background-color: #E8FDF2;
}

.otp-box {
  width: 440px;
  height: 420px;
  max-width: 100%;
  max-height: 90%;
  background-color: #fffcf8;
  border-radius: 25px;
  box-shadow: 3px 3px 18px 3px #ececec;
  margin-top: 140px;
  margin-left: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.otp-box-heading {
  font-size: 25px;
  text-align: center;
  font-weight: 600;
  font-family: "Quicksand";
  color: #394b42;
}

.form-inner-stuff-lcm {
    margin-top: 20px;
}

.form-inner-stuff-lcm input {
    width: 360px;
    border-radius: 50px;
    background-color: #fffcf8;
    box-shadow: 1px 1px 10px 1px #ececec;
    border: 0px;
    padding: 12px;
    outline: none;
}

.alert-field-fisl {
    color: red;
    font-size: 12.5px;
    margin: 0px;
    letter-spacing: 1px;
    font-weight: 600;
    font-family: "Quicksand";
    margin-left: 10px;
    margin-top: 5px;
}

.btn-otpmain {
    width: 360px;
    margin-top: 20px;
}

.btn-otpmain button {
    width: 100%;
    height: 45px;
    border-radius: 50px;
    background-color:#49AF7C;
    border: none;
    outline: none;
    color: white;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 0.5px;
    font-family: "Quicksand";
}

.or-main-lw {
    width: 360px;
    display: flex;
    margin-top: 20px;
    align-items: center;
}

.small-line-or {
    flex-grow: 1;
    height: 0.8px;
    background-color: #394b42;
}

.noaccountpara {
    width: 360px;
    color: #394b42;
    font-weight: 600;
    margin-top: 15px;
    border-radius: 50px;
    background-color:#E8EBFF;
    border: none;
    outline: none;
    font-size: 15px;
    letter-spacing: 1px;
    font-family: "Quicksand";
    text-align: center;
    padding: 11.5px;
}

.noaccountpara a {
    color: #394b42;
}

.otp-box-Image {
  width: 450px;
  height: 450px;
  max-width: 100%;
  max-height: 90%;
  margin-top: 140px;
  margin-left: 150px;
  z-index: 99;
  text-align: center;
  padding: 20px;
  transition: background-color 1s ease-in-out;
}

.otp-box-Image h3 {
  font-size: 30px;
  font-weight: 600;
  font-family: "Quicksand";
  color: #394b42;
  margin-bottom: 10px;
  margin-top: 20px;
}

.otp-box-Image p {
  font-size: 20px;
  font-weight: 400;
  font-family: "Quicksand";
  color: #394b42;
  margin-bottom: 70px;
}

.otp-box-Image img {
  width: 100%;
  height: 230px;
  object-fit: contain;
}

.otp-txtinput{
  font-size: 15px;
  font-weight: 400;
  font-family: "Quicksand";
  color: #394b42;
}

.opt-number{
  font-weight: 600;
  text-align: center;
  text-decoration: underline;
}

.otp-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 20px 0;
}

.otp-input {
  width: 45px;
  height: 50px;
  font-size: 18px;
  text-align: center;
  border: 0px;
  border-radius: 25px;
  box-shadow: 1px 1px 10px 1px #ececec;
  background-color: #fffcf8;
}

.otp-input:focus {
  outline: none;
  border: none;
}

/* Default styles remain the same */

@media (max-width: 1024px) {
  .otp-screen-main-container {
    flex-direction: column;
    height: auto;
    align-items: center;
    padding: 20px;
  }

  .otp-box {
    width: 90%;
    margin: 80px auto;
  }

  .otp-box-Image {
    width: 90%;
    margin: 20px auto;
    height: auto;
  }

  .otp-box-Image img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 768px) {
  .otp-box {
    width: 100%;
    height: auto;
    padding: 20px;
  }

  .form-inner-stuff-lcm input {
    width: 100%;
  }

  .btn-otpmain {
    width: 100%;
  }

  .otp-container {
    gap: 5px;
  }

  .otp-input {
    width: 40px;
    height: 45px;
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .otp-box-heading {
    font-size: 20px;
  }

  .otp-container {
    gap: 3px;
  }

  .otp-input {
    width: 35px;
    height: 40px;
    font-size: 14px;
  }

  .btn-otpmain button {
    font-size: 14px;
    height: 40px;
  }
}

.mobileScreen-otp-box-Image {
  max-width: 100%;
  max-height: 90%;
  margin-top: 90px;
  justify-content: center;
  z-index: 99;
  text-align: center;
  transition: background-color 1s ease-in-out;
}

.mobileScreen-otp-box-Image h3 {
  font-size: 25px;
  font-weight: 600;
  font-family: "Quicksand";
  color: #394b42;
}

.mobileScreen-otp-box-Image img {
  width: 100%;
  height: 230px;
  object-fit: contain;
}
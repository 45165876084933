.bys-screen-main-container {
  width: 100%;
  margin: 0px auto;
  min-height: 100vh;
  display: flex;
  background-color: #FCF2D8;
}

.bys-box {
  /* width: 850px !important; */
  padding-left: 25px;
  padding-right: 25px;
  max-width: 100%;
  min-height: 70vh;
  background-color: #fffcf8;
  border-radius: 25px;
  box-shadow: 1px 1px 9px 1px #ececec;
  margin-top: 100px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;
}

.bys-box-heading {
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  font-family: "Quicksand";
  color: #394b42;
  margin-top: 0px !important;
  margin-bottom: 5px;
}

/* Gender Selection Styling */
.gender-selection {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 5px;
}

.gender-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 15px;
  text-align: center;
  font-weight: 400;
  font-family: "Quicksand";
  color: #394b42;
  cursor: pointer;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

/* Make selected gender image larger */
.gender-option.selected {
  transform: scale(1);
  opacity: 1;
}

/* Reduce the size of non-selected images */
.gender-option:not(.selected) {
  transform: scale(0.9);
}

/* Image styling */
.gender-option img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  padding: 10px;
  background: transparent;
  transition: transform 0.3s ease;
}

/* Highlight selected image */
.gender-option.selected img {
  width: 80px;
  height: 80px;
  background: #fde4ec;
  border: 6px solid #fffcf8;
  box-shadow: 1px 1px 5px 1px #ececec;
}

.build-your-space-form-inner-stuff-lcm {
    margin-top: 10px;
}

.build-your-space-form-inner-stuff-lcm input {
    max-width: 100%;
    border-radius: 50px;
    background-color: #fffcf8;
    box-shadow: 1px 1px 10px 1px #ececec;
    border: 0px;
    padding: 12px;
    outline: none;
}

.build-your-space-form-inner-stuff-lcm input.full-width {
  width: 100% !important;
}

/* Style the select dropdown to look like the input fields */
.build-your-space-form-inner-stuff-lcm select {
  width: 100%;
  max-width: 100%;
  padding: 12px;
  background-color: #fffcf8;
  border-radius: 50px;
  box-shadow: 1px 1px 10px 1px #ececec;
  border: none;
  font-family: "Quicksand";
  font-size: 16px;
  color: #394b42;
  outline: none;
}

/* Optional: Styling for the selected option in the dropdown */
.build-your-space-form-inner-stuff-lcm select:focus {
  box-shadow: 0 0 5px 2px #49AF7C;
}


.form-row {
  display: flex;
  gap: 10px;
  justify-content: center;
  width: 100%;
}

.form-row .build-your-space-form-inner-stuff-lcm {
  /* flex: 1; */
  /* width:320px */
}

.alert-field-fisl {
    color: red;
    font-size: 12.5px;
    margin: 0px;
    letter-spacing: 1px;
    font-weight: 600;
    font-family: "Quicksand";
    margin-left: 10px;
    margin-top: 5px;
}

.bys-btn-loginmain {
    width: 360px;
    margin-top: 20px;
}

.bys-btn-loginmain button {
    width: 100%;
    height: 45px;
    border-radius: 50px;
    background-color:#49AF7C;
    border: none;
    outline: none;
    color: white;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 0.5px;
    font-family: "Quicksand";
}

.seperation-line {
    /* flex-grow: 1; */
    height: 0.8px;
    background-color: #394b42;
    width: 90%;
    align-self: center;
}

.bys-otp-txt{
  color: #394b42;
  font-size: 12.5px;
  font-weight: 400;
  font-family: "Quicksand";
  margin-top: 15px;
  align-self: flex-start;
  margin-bottom: 0px;
  padding-left: 20px;
}

.bys-box-Image {
  height: 230px;
  max-width: 100%;
  max-height: 90%;
  margin-top: 200px;
  z-index: 99;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 380px;
  margin-left: 250px;
}

.bys-box-Image h2 {
  font-size: 50px;
  font-weight: 600;
  font-family: "Quicksand";
  color: #394b42;
  margin-bottom: 10px;
}

.bys-box-Image p {
  font-size: 20px;
  font-weight: 400;
  font-family: "Quicksand";
  color: #394b42;
  margin-bottom: 70px;
}

.bys-box-Image img {
  width: 100%;
  height: 230px;
  object-fit: contain;
  right: 0px;
}

.bys-btn-Continuemain {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.bys-btn-Continuemain button {
  width: 100%;
  height: 45px;
  border-radius: 50px;
  background-color:#49AF7C;
  border: none;
  outline: none;
  color: white;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.5px;
  font-family: "Quicksand";
}

.progress-indicator {
  display: flex;
  gap: 8px;
  margin-top: 25px;
  align-self: flex-start;
  /* margin-left: 30px; */
}

.step {
  width: 55px;
  height: 6px;
  background-color: #d3d3d3;
  border-radius: 5px;
  transition: background-color 0.5s ease-in-out;
}

.step.active {
  background-color: #49AF7C;
}

.mobileScree-bys-screen-main-container {
  width: 100%;
  margin: 0px auto;
  min-height: 100vh;
  background-color: #FCF2D8;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobileScreen-heading {
  font-size: 25px;
  font-weight: 600;
  font-family: "Quicksand";
  color: #394b42;
  padding-top: 105px !important;
  margin-bottom: 5px;
}

.mobileScreen-bys-btn-Continuemain {
  width: 90%;
  margin-top: 20px;
  bottom: 45px;
}

.mobileScreen-bys-btn-Continuemain button {
  width: 100%;
  height: 45px;
  border-radius: 50px;
  background-color:#49AF7C;
  border: none;
  outline: none;
  color: white;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.5px;
  font-family: "Quicksand";
}

/* corporate redirect modal */
.corporate-redirect-modal{
  font-family: "Quicksand", sans-serif;
  z-index: 999999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #FFFCF8;
  width: 360px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 40px;
}
.corporate-redirect-head{
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}
.sbtns{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  width:90%;
  align-self: center;
}
.corporate-redirect-btn{
  background: #49AF7C;
  color:#FFFCF8;
  font-size: 15px;
  font-weight: 500;
  width:45%;
  height:40px;
  border-radius: 32px;
  border:none;
  outline:none;
}
.corporate-redirect-btn.no{
  background: #C4EED9;
  color:#394b42;
  font-weight: 500;
}

.bys-dropdown-container {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #fffcf8;
  border-radius: 25px;
  width: 90%;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 12px;
}

.bys-input-box {
  display: flex;
  align-items: center;
  background-color: #DCF6E9;
  border-radius: 25px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 10px;
  font-size: 15px;
  cursor: pointer;
  justify-content: space-between;
}

.bys-selected-code {
  color: #394b42;
}

.bys-arrow {
  font-size: 8px;
  color: #394b42;
}

.bys-dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #fffcf8;
  border-radius: 10px;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 999;
}

.bys-dropdown-list li {
  padding: 10px;
  cursor: pointer;
  list-style: none;
}

.bys-dropdown-list li:hover {
  background: #b8e0d2;
}

.bys-phone-input {
  flex: 1;
  border: none;
  padding: 10px;
  font-size: 15px;
  outline: none;
  background-color: #fffcf8;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
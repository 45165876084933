.dailygoals-modal{
    font-family: "Quicksand", sans-serif;
    z-index: 999999;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #FFFCF8;
    width: 550px;
    height:350px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    padding: 20px 30px;
}
.goals-top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0px 20px 0px;
}
.goals-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.goals-head p{
    margin:0;
}
.save-goal{
    width:181px;
    height:55px;
    border:none;
    outline:none;
    border-radius: 32px;
    background: #49AF7C;
    font-size: 1.28em;
    font-weight: 500;
    color:white;
    align-self: center;
    margin-top: 30px;
}
.icon-div.meditation{
    width:80px;
    height:80px;
    border-radius: 50%;
    background: #CBD1F0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    border:none;
}
.icon-div.yoga{
    background: #FADD8A;
    width:80px;
    height:80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    border:none;
}
.goals-value{
    width:100%;
    height:140px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.goals-value::-webkit-scrollbar{
    display:none;
}
.goal-amount {
    color: #394B4280;
    font-size: 1.25em;
    font-weight: 600;
    width:40%;
    align-self: center;
    text-align: center;
    border-bottom: 1px solid #394B4233;
    padding: 10px 0;
    cursor: pointer;
}
.goal-amount.selected{
    color:#394B42
}
.goal-amount p{
    margin:0;
}
.add-goal{
    width:60px;
    height:60px;
    border-radius: 50%;
    background: #49AF7C;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
.create-goal{
    font-size: 1.5em;
    font-weight: 600;
}
.add-div{
    display: flex;
    margin: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width:787px){
    .dailygoals-modal{
        width:80%;
    }
}